import React, { useRef } from "react";
import Banner from "../components/Banner";
import WhyBid from "../components/WhyBid";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import About from "../components/About";
import How from "../components/How";
function Home() {
  const catRef = useRef();

  return (
    <>
      <Banner btnRef={catRef} />
      <div ref={catRef}>
        <WhyBid />
      </div>
      <How />
      <About />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
