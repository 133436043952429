import React from "react";
import "../assets/styles/bannerCards.css";
import userImage from "../assets/images/userImage.png";
import userAppIcon from "../assets/images/userAppIcon.svg";
import googlePlayIcon from "../assets/images/googlePlayIcon.svg";
import AppstoreIcon from "../assets/images/AppstoreIcon.svg";
const UserCardDesktop = () => {
  return (
    <div
      className="driver-card-part user-card-part-desktop"
      style={{
        width: "50%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        className="card-banner"
        style={{
          position: "absolute",
          right: "30px",
          top: "40px",
        }}
      >
        <div
          className="left r-left"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            padding: "20px",
            backgroundColor: "#6F52EA",
            color: "white !important",
          }}
        >
          <span
            className="span-card-banner-1"
            style={{
              color: "white !important",
            }}
          >
            Sərnişin
          </span>
          <span
            className="span-card-banner-2"
            style={{
              marginTop: "20px",
              color: "white !important",
            }}
          >
            Taksinizi özünüz seçin
          </span>
          <span
            className="span-card-banner-2"
            style={{
              marginTop: "20px",
              color: "white !important",
            }}
          >
            Hər yerdə sərfəli tarif
          </span>
          <span
            className="span-card-banner-4"
            style={{
              marginTop: "130px",
              fontSize: "52px",
            }}
          >
            3 <span className="span-card-banner-5">AZN</span>
          </span>
          <span className="span-card-banner-3">dan başlayan qiymətlərlə</span>
        </div>
      </div>
      <div
        className="type-info"
        style={{
          position: "absolute",
          left: "40px",
          bottom: "100px",
        }}
      >
        <img src={userAppIcon} alt="driver app icon" />
        <span>Bid: Sərnişin</span>
      </div>
      <div
        className="type-urls"
        style={{
          position: "absolute",
          left: "40px",
          bottom: "40px",
        }}
      >
        <a
          href="https://play.google.com/store/apps/details?id=com.bid.iste"
          target="_blank"
        >
          <img src={googlePlayIcon} alt="google play icon" />
        </a>
        <a
          href="https://apps.apple.com/az/app/bid/id6498927869"
          target="_blank"
        >
          <img src={AppstoreIcon} alt="app store icon" />
        </a>
      </div>
    </div>
  );
};

export default UserCardDesktop;
