import React from "react";
import "../assets/styles/footer.css";
import { Link } from "react-scroll";
import bidPurple from "../assets/images/BiD-LOQO-purple.svg";
import phone from "../assets/images/phone.svg";
import location from "../assets/images/location.svg";
import mail from "../assets/images/mail.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
function Footer() {
  const facebook = (
    <FontAwesomeIcon className="footer-social-icon" icon={faFacebookF} />
  );
  const instagram = (
    <FontAwesomeIcon className="footer-social-icon" icon={faInstagram} />
  );
  const linkedin = (
    <FontAwesomeIcon className="footer-social-icon" icon={faLinkedinIn} />
  );

  return (
    <div className="Footer">
      <img className="bid-logo" src={bidPurple} alt="bid-logo" />
      <p className="footer-text">
        Yolçu <img className="bid-logo-small" src={bidPurple} alt="" />
        -də gərək!
      </p>
      <div className="footer-links">
        <li>
          <Link to="why" spy={true} smooth={true}>
            Nə üçün bid?
          </Link>
        </li>
        <li>
          <Link to="how" spy={true} smooth={true}>
            Necə işləyir?
          </Link>
        </li>
        <li>
          <Link to="about" spy={true} smooth={true}>
            Haqqımızda
          </Link>
        </li>
        <li>
          <Link to="faq" spy={true} smooth={true}>
            FAQ
          </Link>
        </li>
        <li>
          <a href="/PrivacyPolicy.html" rel="external nofollow noopener" target="_blank" 
          style={{
            color:'inherit',
            textDecoration:'none'
          }} >Privacy policy</a>
          </li>
      </div>
      <div className="footer-contact-list">
        
        <a href="mailto: info@bid.az" className="footer-email">
          <div className="footer-circle">
            <img src={mail} alt="mail-icon" />
          </div>
          info@bid.az
        </a>
       
      </div>

      <div className="footer-socials">
        <a
          href="https://www.facebook.com/Bid-TAXI-107556738608969/?ref=pages_you_manage"
          target="_blank"
          rel="noreferrer"
          className="footer-social-icon"
        >
          {facebook}
        </a>
        <a
          href="https://www.instagram.com/bid_taxi/"
          target="_blank"
          rel="noreferrer"
          className="footer-social-icon"
        >
          {instagram}
        </a>
        <a
          href="https://www.linkedin.com/company/81373059/admin/"
          target="_blank"
          rel="noreferrer"
          className="footer-social-icon"
        >
          {linkedin}
        </a>
      </div>
      <div className="horizontal-line"></div>
      <p className="copyright">
        Copyright &copy; {new Date().getFullYear()} bid. Bütün hüquqlar qorunur.
      </p>
    </div>
  );
}

export default Footer;
