import React from "react";
import "../assets/styles/registerPopUp.css";
import driverAppIcon from "../assets/images/driverAppIcon.svg";
import googlePlayIcon from "../assets/images/googlePlayIcon.svg";
import AppstoreIcon from "../assets/images/AppstoreIcon.svg";

const RegisterPagePopUp = () => {
  return (
    <div className="register-popup">
      <div className="register-popup-content">
        <span
          className="register-popup-content-span-2"
          style={{
            marginTop: "20px",
          }}
        >
          Daha az komissiya,
        </span>
        <span className="register-popup-content-span-2">Daha çox Qazanc!</span>
        <span
          className="register-popup-content-span-3"
          style={{
            marginTop: "30px",
          }}
        >
          Hər gediş üçün sadəcə
        </span>
        <span className="register-popup-content-span-3">
          <span className="register-popup-content-span-4">0.30</span>
          <span className="register-popup-content-span-5"> AZN</span> komissiya
          ödəyin.
        </span>
        <span
          className="register-popup-content-span-3"
          style={{
            marginTop: "30px",
          }}
        >
          <span className="register-popup-content-span-4">+ 1500</span>
          <span className="register-popup-content-span-5"> AZN</span> - ə qədər
          aylıq əlavə qazanc əldə edin!
        </span>
        <div
          className="type-info"
          style={{
            marginTop: "20px",
          }}
        >
          <img src={driverAppIcon} alt="driver app icon" />
          <span>Bid: Sürücü</span>
        </div>
        <div className="type-urls">
          <a
            href="https://play.google.com/store/apps/details?id=com.bidriver"
            target="_blank"
          >
            <img src={googlePlayIcon} alt="google play icon" />
          </a>
          <a
            href="https://apps.apple.com/az/app/bid-driver/id6497953366"
            target="_blank"
          >
            <img src={AppstoreIcon} alt="app store icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RegisterPagePopUp;
