import React, { useState, useEffect } from "react";
import BaseApi from "../api/BaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/register.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowDown,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import PhoneInput from "react-phone-number-input/input";
import bidLogoPurple from "../assets/images/BiD-LOQO-purple.svg";
import profilpic from "../assets/images/profile-pic.jpg";

import { carsName as allCarNames } from "./allCarsData";
import { getCarModels } from "./allCarsData";
import { getCarYears } from "./allCarsData";
import Select from "react-select";

export default function SimpleSliderFleet() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows:false,
  };

  const [color, setColor] = useState("");

  const searchIcon = (
    <FontAwesomeIcon className="search-icon" icon={faAngleDown} />
  );

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(new Date());
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [phone, setPhone] = useState();
  const [carCount, setCarCount] = useState();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [validationEmailExist, setValidationEmailExist] = useState(false);
  const [validationEmail, setValidationEmail] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidSurname, setIsValidSurname] = useState(true);
  const [isValidCarCount, setIsValidCarCount] = useState(true);
  const [isValidDatetime, setIsValidDatetime] = useState(true);
  const [isValidAge, setisValidAge] = useState(true);
  const [isValidGender, setIsValidGender] = useState(true);
  const [isValidPin2, setIsValidPin2] = useState(true);
  const [isValidColor, setIsValidColor] = useState(true);
  const [isValidSeat, setIsValidSeat] = useState(true);

  const [fileInput, setFileInput] = useState(profilpic);
  const [taxiCardImg, setTaxiCardImg] = useState(" ");
  const [taxiCertificateImg, setTaxiCertificateImg] = useState(" ");
  const [plateNum, setPlateNum] = useState("");
  const [isValidPlateNum, setIsValidPlateNum] = useState(true);

  const [numofSeats, setNumOfSeats] = useState("");
  const eyeIcon = (
    <FontAwesomeIcon
      style={{
        fontSize: "15px",
        color: "black",
        fontWeight: "bolder",
      }}
      icon={faEye}
    />
  );
  const eyeClose = (
    <FontAwesomeIcon
      style={{
        fontSize: "15px",
        color: "black",
        fontWeight: "bolder",
      }}
      icon={faEyeSlash}
    />
  );
  useEffect(() => {
    setIsValidNumber(true);
  }, [phone]);

  const validatePhoneNumber = (phone) => {
    if (phone !== undefined) {
      setIsValidNumber(isValidPhoneNumber(phone));
      if (isValidPhoneNumber(phone)) {
        return true;
      }
    } else {
      setIsValidNumber(false);
    }
  };

  const validateNameSurname = (name, surname) => {
    if ((name.length <= 2) | (name.length >= 20)) {
      setIsValidName(false);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    } else {
      setIsValidName(true);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    }
  };
  const validateCarCount = () => {
    if (!carCount) {
      setIsValidCarCount(false);
      return false;
    }
    return true;
  };

  const request = async () => {
    const fd = new FormData();
    fd.append("name", name);
    fd.append("lastname", surname);
    fd.append("mobileNum", phone);
    fd.append("car", carCount);
    const response = await BaseApi.post("/registerAsFleet", fd);
    if (response.data.statusCode === 200) {
      toast.success("Qeydiyyatınız uğurla nəticələndi", { autoClose: 2000 });
    } else {
      toast.error(response.data.message);
    }
  };

  const HandleChange = (e, num) => {
    switch (num) {
      case 1:
        setName(e.target.value);
        setIsValidName(true);
        break;
      case 2:
        setEmail(e.target.value);
        setValidationEmail(true);
        setValidationEmailExist(false);
        break;
      case 3:
        setIsValidNumber(true);
        break;
      case 4:
        setDate(e);
        setIsValidDatetime(true);
        setisValidAge(true);
        break;
      case 5:
        setSurname(e.target.value);
        setIsValidSurname(true);
        break;
      case 8:
        setMale(e.target.checked);
        setIsValidGender(true);
        break;
      case 9:
        setFemale(e.target.checked);
        setIsValidGender(true);
        break;
      case 10:
        e.target.value >= 0 &&
          e.target.value <= 100 &&
          setCarCount(
            e.target.value
              ?.replace("-", "")
              ?.replace("+", "")
              ?.replace("--", "")
              ?.replace("++", "")
          );
        setIsValidCarCount(true);
        break;
      case 11:
        setPin2(e.target.value);
        setIsValidPin2(true);
        break;
      case 14:
        setNumOfSeats(e.target.value);
        setIsValidSeat(true);
        break;
      case 15:
        setPlateNum(e.target.value);
        setIsValidPlateNum(true);
        break;
      case 16:
        setColor(e.value);
        setIsValidColor(true);
        break;
      default:
        break;
    }
  };

  const HandleSubmit = () => {
    let phoneNumberValid = validatePhoneNumber(phone);
    let nameSurnameValid = validateNameSurname(name, surname);
    let carCountValid = validateCarCount(carCount);
    if (phoneNumberValid && nameSurnameValid && carCountValid) {
      request();
    }
  };

  return (
    <>
      <ToastContainer />

      <Slider {...settings}>
        <div className="register-right-container 1">
          <div className="register-right-top">
            <div className="register-right-text-container">
              <h1
                className="register-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={bidLogoPurple}
                  alt="bid-logo"
                  className="bid-right-logo"
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginTop: "10px",
                    color: "#6F52EA",
                    fontWeight: "700",
                  }}
                >
                  Sahibkar
                </span>
              </h1>
              <p className="welcome">Xoş Gəlmişsiniz!</p>
            </div>
            <label htmlFor="file-input" className="profile-photo"></label>
          </div>
          <form
            className="form-register"
            onSubmit={(e) => {
              e.preventDefault();
              HandleSubmit();
            }}
          >
            <div className="form-register-inputs">
              <div className="register-field-two-inputs">
                <div className="register-field name">
                  <label htmlFor="name">Ad *məcburi</label>
                  <input
                    placeholder="Ad"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => HandleChange(e, 1)}
                  />
                  {!isValidName ? (
                    <div className="error-message">
                      Ad ən az 2 və ən çox 20 simvol ola bilər
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="register-field surname">
                  <label htmlFor="surname">Soyad *məcburi</label>
                  <input
                    placeholder="Soyad"
                    type="text"
                    id="surname"
                    value={surname}
                    onChange={(e) => HandleChange(e, 5)}
                  />
                  {!isValidSurname ? (
                    <div className="error-message">
                      Soyad ən az 2 və ən çox 20 simvol ola bilər
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="register-field phone">
                <label htmlFor="phone">Telefon *məcburi</label>
                <PhoneInput
                  className="phone-input"
                  id="phone"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="AZ"
                  value={phone}
                  onChange={setPhone}
                />
                <span className="country-code">+994</span>
              </div>
              {!isValidNumber ? (
                <div className="error-message">Nömrə düzgün deyil</div>
              ) : (
                <></>
              )}
              <div className="register-field phone">
                <label htmlFor="phone">
                  Avtoparkdaki avtomobillərin sayı *məcburi
                </label>
                <input
                  placeholder="Avtomobil sayı"
                  type="number"
                  id="carCount"
                  value={carCount
                    ?.replace("-", "")
                    ?.replace("+", "")
                    ?.replace("--", "")
                    ?.replace("++", "")}
                  min={0}
                  max={100}
                  onChange={(e) => HandleChange(e, 10)}
                />
              </div>
              {!isValidCarCount ? (
                <div className="error-message">Avtomobil sayı qeyd edin</div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="register-buttons"
              style={{
                bottom: "0",
              }}
            >
              <button className="create-acc" type="submit">
                Qeydiyyatdan keç
              </button>
            </div>
          </form>
        </div>
      </Slider>
    </>
  );
}
