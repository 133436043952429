import React from 'react'
import '../assets/styles/about.css'
import prius from '../assets/images/prius.png'
import sl from '../assets/images/biznes.png'
import xl from '../assets/images/xl.png'
import bidPurple from '../assets/images/BiD-LOQO-purple.svg'

function About (props) {
  return (
    <div className='About' id='about'>
      <h2 className='about-heading'>Haqqımızda</h2>
      <p className='about-small-text'>
        Biz etdiyimiz her şeyə peşəkarlıq gətiririk.
      </p>
      <p className='about-content'>
        {/* <span>bid</span> mobil tətbiqetməsi 2022-ci ildə <span>bid</span> MMC
        tərəfindən yaradılmışdır. Bu mobil tətbiqetmənin əsas funksiyası taksi
        fəaliyyəti göstərmək istəyən sürücülərlə taksi xidmətlərindən
        yararlanmaq istəyən sərnişinlər arasında vasitəçi rolunu oynamaqdır.
        Bununla yanaşı <span>bid</span> yalnız sərnişinlərin deyil, eyni zamanda
        sürücülərin komfortu barəsində düşünür ki, nəticədə hər iki tərəf
        <span>bid</span> mobil tətbiqetməsinin bu xidmətlərindən razı qalmış
        olur! */}
        <span>“Bid Taxi”</span> mobil tətbiqi Azərbaycan mütəxəssisləri tərəfindən
        yaradılmış, müəllif hüquqları Azərbaycan Respublikası Əqli Mülkiyyət
        Agentliyində qeydiyyata alınaraq müvafiq qanunvericiliklə qorunan
        komputer proqramıdır. Müəllifliklə əlaqəli bütün hüquqlar <span>“Bid Taxi”</span>
        MMC-yə məxsusdur. Əsas məqsədi taxsi xidmətinin mobil tətbiq üzərindən
        təşkili olan bu layihəyə Azərbaycan Respublikasının kiçik və orta
        biznesin İnkişafı Agentliyi tərəfindən Startap Şəhadətnaməsi, AYDA
        tərəfindən isə 31.05.2024-cü il tarixli TSO № 10012 taksi sifarişi
        operatoru fəaliyyətinə icazə verilmişdir. <span>“Bid Taxi”</span> mobil tətbiqi
        sərnişinlərlə taksi xidməti göstərən şəxslərin ən sürətli şəkildə əlaqə
        yaratmalarını, sifarişlərin qəbulunu və hər iki tərəfin qəbul etdiyi
        əlverişli şərtlərlə taxsi xidmətinin reallaşmasını təmin edir. Qayəmiz
        hər kəsə ədalətli qiymətlə komfortlu taxsi xidmətinin əlçatımlı olması
        imkanına nail olmaqdır.
      </p>

      <div className='about-bg'>
        <div className='about-bg-container'>
          <h3 className='about-bg-heading'>Nəqliyyat vasitələrimiz</h3>
          <div className='about-cards'>
            <div className='about-card'>
              <img src={prius} alt='' />
              <h3 className='about-card-title'>Ekonom</h3>
            </div>
            <div className='about-card'>
              <img src={sl} alt='' />
              <h3 className='about-card-title'>Biznes</h3>
            </div>
            <div className='about-card'>
              <img src={xl} alt='' />
              <h3 className='about-card-title'>XL</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
