import React, { useState } from 'react'
import '../assets/styles/why.css'
import bidPurple from '../assets/images/BiD-LOQO-purple.svg'
import card1 from '../assets/images/Pul-ikonu.svg'
import card1W from '../assets/images/PUL-iKON-Ag.svg'

import card2 from '../assets/images/fellow-icon.svg'
import card2W from '../assets/images/fellow-icon-ag.svg'

import card3 from '../assets/images/info-icon.svg'
import card3W from '../assets/images/info-icon-ag.svg'

import card4 from '../assets/images/TiP.svg'
import card4W from '../assets/images/TiP.svg'

function WhyBid (props) {
  const [card, setCard] = useState([false, false, false, false])
  return (
    <div id='why' className='Why-Bid'>
      <h3 className='why-heading'>
        <p>Nə üçün </p>
        <p style={{ color: '#6F52EA' }}>
          <span style={{ fontFamily: 'Comfortaa', marginLeft: '5px' }}>
            bid
          </span>
          -i seçməlisiniz?
        </p>
      </h3>
      <p className='why-small-text'>
        Biz maksimum dərəcədə sizin büdcənizi və rahatlığınızı düşünürük
      </p>

      <div className="cards-container">
        <div
          className='card'
          onMouseEnter={() => setCard([false, true, false, false])}
          onMouseLeave={() => setCard([false, false, false, false])}
        >
          <div className='card-inner-container'>
            <img
              src={
                window.innerWidth < 670
                  ? card2
                  : window.innerWidth > 670 && card[1]
                    ? card2
                    : card2
              }
              alt=''
              className='card-logo'
            />
            <div className='hr-line'></div>
            <h2 className='card-header'>Ən optimal şərtlərlə səyahət edin!</h2>
            <p className='card-content'>
              Göndərilən təklifləri dəyərləndirərək, sizin üçün ən optimal
              şəraitlərdə səyahət edin!
            </p>
          </div>
        </div>
        <div
          className='card'
          onMouseEnter={() => setCard([false, false, true, false])}
          onMouseLeave={() => setCard([false, false, false, false])}
        >
          <div className='card-inner-container'>
            <img
              src={
                window.innerWidth < 670
                  ? card1W
                  : window.innerWidth > 670 && card[2]
                    ? card1W
                    : card1
              }
              alt=''
              className='card-logo'
            />
            <div className='hr-line'></div>
            <h2 className='card-header'>Komissiyasız!</h2>
            <p className='card-content'>
            Hər gediş üçün balansdan 0.30 AZN çıxılacaq.
            </p>
          </div>
        </div>
        <div
          className="card"
          onMouseEnter={() => setCard([false, false, false, true])}
          onMouseLeave={() => setCard([false, false, false, false])}
        >
          <div className='card-inner-container'>
            <img
              src={window.innerWidth > 670 && card[3] ? card3W : card3}
              alt=''
              className='card-logo'
            />
            <div className='hr-line'></div>

            <h2 className='card-header'>
              Gediş haqqında ətraflı məlumat əldə edin!
            </h2>
            <p className='card-content'>
              Gediş başlamadan öncə başlanğıc və təyinat nöqtəsi barədə
              məlumatlanma imkanı!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyBid
