import React, { useState } from "react";
import Navbar from "./Navbar";
import "../assets/styles/banner.css";
import bidLogoOnText from "../assets/images/bidLogoOnText.svg";
import UserCard from "./UserCard";
import DriverCard from "./DriverCard";
import driverImage from "../assets/images/driverImage.svg";
import userImage from "../assets/images/userImage.png";

function BannerMobile({ btnRef }) {
  const goToCategories = () => {
    window.scrollTo({ top: btnRef.current.offsetTop, behavior: "smooth" });
  };
  const [selecteType, setSelectedType] = useState(0);
  return (
    <>
      <div className="banner-content">
        <h1 className="header-banner">
          Yolçu{" "}
          <img
            className="header-banner-img"
            src={bidLogoOnText}
            alt="bid logo"
          />{" "}
          - də gərək!
        </h1>
        <div
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div className="navigation-driver-user">
            <div
              onClick={() => setSelectedType(0)}
              className={`navigation-driver-user-element ${
                selecteType === 0 ? "active" : ""
              }`}
            >
              Sürücü
            </div>
            <div
              onClick={() => setSelectedType(1)}
              className={`navigation-driver-user-element ${
                selecteType === 1 ? "active" : ""
              }`}
            >
              Sərnişin
            </div>
          </div>
          <div className="selected-type-cards">
            {selecteType === 0 ? <DriverCard /> : <UserCard />}
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerMobile;

{
  /* <div className="banner-content">
  <div className="banner-left">
    <div className="banner-texts">
      <h1 className="banner-heading">
        Yolçu
        <div>
          <span>bid</span>
        </div>
        -də gərək!
      </h1>
      <p className="banner-small-text">
        Sizin rahatlığınızı və büdcənizi düşünən bir tətbiq
      </p>
      <div className="social-links">
        <img className="stores-logo" src={playStore} alt="play store" />
        <img className="stores-logo" src={appStore} alt="app store" />
      </div>
      <div className="scroll-down" onClick={goToCategories}>
        <img src={mouse} alt="scroll-bar-icon" className="scroll-down-mouse" />
      </div>
    </div>
  </div>
  <div className="banner-right">
    <div className="banner-right-image"></div>
  </div>
</div>; */
}
