import "./App.css";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import RegisterFleet from "./pages/RegisterFleet";

function App() {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-fleet" element={<RegisterFleet />} />
        </Routes>
      </>
    </Router>

  );
}

export default App;
