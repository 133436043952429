import React, { useState, useEffect } from "react";
import BaseApi from "../api/BaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/register.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowDown,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import PhoneInput from "react-phone-number-input/input";
import bidLogoPurple from "../assets/images/BiD-LOQO-purple.svg";
import profilpic from "../assets/images/profile-pic.jpg";

import { carsName as allCarNames } from "./allCarsData";
import { getCarModels } from "./allCarsData";
import { getCarYears } from "./allCarsData";
import Select from "react-select";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows:false,
  };

  const [data, setData] = useState({
    carName: null,
    carModel: null,
    carYear: null,
    numSeats: "",
    plateNumber: "",
    voen: "",
  });
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "rgba(204, 204, 204, 0.7)" : "",
    }),
  };
  const options = [
    { value: "Bej", label: "Bej" },
    { value: "Qara", label: "Qara" },
    { value: "Göy", label: "Göy" },
    { value: "Bronze", label: "Bronze" },
    { value: "Qəhvəyi", label: "Qəhvəyi" },
    { value: "Qızılı", label: "Qızılı" },
    { value: "Boz", label: "Boz" },
    { value: "Yaşıl", label: "Yaşıl" },
    { value: "Narıncı", label: "Narıncı" },
    { value: "Çəhrayı", label: "Çəhrayı" },
    { value: "Bənövşəyi", label: "Bənövşəyi" },
    { value: "Qırmızı", label: "Qırmızı" },
    { value: "Gümüşü", label: "Gümüşü" },
    { value: "Ağ", label: "Ağ" },
    { value: "Sarı", label: "Sarı" },
  ];
  //----------Car name------------------
  const [carNames, setCarNames] = useState([]);
  const [carListVisible, setCarListVisible] = useState(false);
  const [carBrand, setCarBrand] = useState("");
  const [carFiltered, setCarFiltered] = useState([]);
  const closeListName = (data) => setCarListVisible(data);
  //--------------- Model--------------
  const [carModels, setCarModels] = useState([]);
  const [carModelListVisible, setCarModelListVisible] = useState(false);
  const [carModel, setCarModel] = useState("");
  const [carModelFiltered, setCarModelFiltered] = useState([]);
  const closeListModel = (data) => setCarModelListVisible(data);
  //------------Year-----------------
  const [carYears, setCarYears] = useState([]);
  const [carYearListVisible, setCarYearListVisible] = useState(false);
  const [carYear, setCarYear] = useState("");
  const [carYearFiltered, setCarYearFiltered] = useState([]);
  const [color, setColor] = useState("");

  const closeListYear = (data) => setCarYearListVisible(data);

  const onChangeData = (txt, key) => {
    setData((prevState) => ({
      ...prevState,
      [key]: txt,
    }));
  };
  useEffect(() => {
    (() => {
      setCarNames(allCarNames);
      setCarFiltered(allCarNames);
    })();
  }, []);

  //--------Car type--------
  const selectCarName = (item) => {
    setCarBrand(item?.title);
    setCarListVisible(false);
    setCarModel([]);
    setCarYear([]);
    setIsValidMarka(true);
    if (item !== null) {
      setData((prevState) => ({
        ...prevState,
        carName: item,
      }));
      const b = getCarModels(item);
      setCarModels(b);
      setCarModelFiltered(b);
    }
  };
  const onSearchCarBrand = (item) => {
    setIsValidMarka(true);
    setCarFiltered(carNames);
    if (item.length > 0) {
      var filtered = carNames.filter((name) =>
        name?.title
          .toUpperCase()
          .includes(item.toUpperCase().trim().replace(/\s/g, ""))
      );
      setCarFiltered(filtered);
      setCarListVisible(true);
    }
    setCarBrand(item);
  };
  //--------Model--------
  const selectCarModel = (item) => {
    setIsValidModel(true);
    setCarModel(item?.title);
    setCarModelListVisible(false);
    setCarYear([]);
    if (item != null) {
      setData((prevState) => ({
        ...prevState,
        carModel: item,
      }));
      const b = getCarYears(item, data.carName);
      setCarYears(b);
      setCarYearFiltered(b);
    }
  };

  const onSearchCarModel = (item) => {
    setIsValidModel(true);

    setCarModelFiltered(carModels);

    if (item.length > 0) {
      var filtered = carModels.filter((name) =>
        name?.title
          .toUpperCase()
          .includes(item.toUpperCase().trim().replace(/\s/g, ""))
      );
      setCarModelFiltered(filtered);
      setCarModelListVisible(true);
    }
    setCarModel(item);
  };
  //--------Car year--------
  const selectCarYear = (item) => {
    setIsValidYear(true);
    setCarYear(item?.title);
    setCarYearListVisible(false);
    if (item != null) {
      setData((prevState) => ({
        ...prevState,
        carYear: item,
      }));
    }
  };
  const onSearchCarYear = (item) => {
    setIsValidYear(true);
    setCarYearFiltered(carYears);

    if (item.length > 0) {
      var filtered = carYears.filter((name) =>
        name?.title
          .toUpperCase()
          .includes(item.toUpperCase().trim().replace(/\s/g, ""))
      );
      setCarYearFiltered(filtered);
      setCarYearListVisible(true);
    }
    setCarYear(item);
  };

  const searchIcon = (
    <FontAwesomeIcon className="search-icon" icon={faAngleDown} />
  );

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(new Date());
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [phone, setPhone] = useState();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [validationEmailExist, setValidationEmailExist] = useState(false);
  const [validationEmail, setValidationEmail] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidSurname, setIsValidSurname] = useState(true);
  const [isValidDatetime, setIsValidDatetime] = useState(true);
  const [isValidAge, setisValidAge] = useState(true);
  const [isValidGender, setIsValidGender] = useState(true);
  const [isValidPin1, setIsValidPin1] = useState(true);
  const [isValidPin2, setIsValidPin2] = useState(true);
  const [isValidMarka, setIsValidMarka] = useState(true);
  const [isValidModel, setIsValidModel] = useState(true);
  const [isValidYear, setIsValidYear] = useState(true);
  const [isValidColor, setIsValidColor] = useState(true);
  const [isValidSeat, setIsValidSeat] = useState(true);

  const [fileInput, setFileInput] = useState(profilpic);
  const [fileInputDownloaded, setFileInputDownloaded] = useState("");
  const [drivingLicenseImg, setDrivingLicenseImg] = useState(" ");
  const [nationalIdImg, setNationalIdImg] = useState(" ");
  const [carLicenseImg, setCarLicenseImg] = useState(" ");
  const [carLicenseBackImg, setCarLicenseBackImg] = useState(" ");
  const [notaryImg, setNotaryImg] = useState(" ");
  const [criminalRecordImg, setCriminalRecordImg] = useState(" ");
  const [bankImg, setBankImg] = useState(" ");
  const [taxiCardImg, setTaxiCardImg] = useState(" ");
  const [taxiCertificateImg, setTaxiCertificateImg] = useState(" ");
  const [technicalImg, setTechnicalImg] = useState(" ");
  const [medicalImg, setMedicalImg] = useState(" ");
  const [voenImg1, setVoenImg1] = useState(" ");
  const [voenImg2, setVoenImg2] = useState(" ");

  const [license, setLicense] = useState("");
  const [licenseFileName, setLicenseFileName] = useState("");
  const [plateNum, setPlateNum] = useState("");
  const [isValidPlateNum, setIsValidPlateNum] = useState(true);

  const [numofSeats, setNumOfSeats] = useState("");
  const eyeIcon = (
    <FontAwesomeIcon
      style={{
        fontSize: "15px",
        color: "black",
        fontWeight: "bolder",
      }}
      icon={faEye}
    />
  );
  const eyeClose = (
    <FontAwesomeIcon
      style={{
        fontSize: "15px",
        color: "black",
        fontWeight: "bolder",
      }}
      icon={faEyeSlash}
    />
  );
  useEffect(() => {
    setIsValidNumber(true);
  }, [phone]);
  const closeValidations = () => {
    setValidationEmailExist(false);
    setValidationEmail(true);
    setIsValidNumber(true);
    setIsValidSurname(true);
    setIsValidName(true);
    setIsValidDatetime(true);
    setisValidAge(true);
    setIsValidGender(true);
    // setIsValidCompanyName(true);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidationEmail(re.test(String(email).toLowerCase()));
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (phone) => {
    if (phone !== undefined) {
      setIsValidNumber(isValidPhoneNumber(phone));
      if (isValidPhoneNumber(phone)) {
        return true;
      }
    } else {
      setIsValidNumber(false);
    }
  };

  const validationGender = (male, female) => {
    if (male || female) {
      setIsValidGender(true);
      return true;
    } else {
      setIsValidGender(false);
    }
  };
  const validationDrivingLicenseImg = (image) => {
    if (image === " " || image === "0") {
      setDrivingLicenseImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationImg = (image) => {
    if (
      (image !== " " || image !== "0" || image !== "") &&
      image != profilpic
    ) {
      setFileInputDownloaded("");
      return true;
    } else {
      setFileInputDownloaded("0");
      return false;
    }
  };
  const validationNationalIdImg = (image) => {
    if (image === " " || image === "0") {
      setNationalIdImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationCarLicenseImg = (image) => {
    if (image === " " || image === "0") {
      setCarLicenseImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationCarLicenseBackImg = (image) => {
    if (image === " " || image === "0") {
      setCarLicenseBackImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationNotaryImg = (image) => {
    if (image === " " || image === "0") {
      setNotaryImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationCriminalRecordImg = (image) => {
    if (image === " " || image === "0") {
      setCriminalRecordImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationBankImg = (image) => {
    if (image === " " || image === "0") {
      setBankImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationTaxiCardImg = (image) => {
    if (image !== " " && image !== "0" && image?.length !== 0) {
      return true;
    } else {
      setTaxiCardImg("");
      return false;
    }
  };
  const validationTaxiCertificateImg = (image) => {
    if (image !== " " && image !== "0" && image?.length !== 0) {
      return true;
    } else {
      setTaxiCertificateImg("");
      return false;
    }
  };
  const validationTechnicalImg = (image) => {
    if (image === " " || image === "0") {
      setTechnicalImg("");
      return true;
    } else {
      return false;
    }
  };
  const validationMedicalImg = (image) => {
    if (image === " " || image === "0") {
      setMedicalImg("");
      return true;
    } else {
      return false;
    }
  };

  const validationDatetime = (date) => {
    if (date !== "") {
      setIsValidDatetime(true);
      // if ((+date.substring(0, 4) > 2006) | (+date.substring(0, 4) < 1930)) {
      //   setisValidAge(false);
      // } else {
        setisValidAge(true);
        return true;
      // }
    } else {
      setIsValidDatetime(false);
      return false;
    }
  };
  const validationPin1 = (image) => {
    if (image === " " || image === "0") {
      setVoenImg1("");
      return true;
    } else {
      return false;
    }
  };
  const validationPin2 = (image) => {
    if (image === " " || image === "0") {
      setVoenImg2("");
      return true;
    } else {
      return false;
    }
  };
  const validationPlateNum = () => {
    if (plateNum === "" || (plateNum.length < 7 && plateNum.length > 0)) {
      setIsValidPlateNum(false);
      return false;
    } else {
      return true;
    }
  };
  const validateNameSurname = (name, surname) => {
    if ((name.length <= 2) | (name.length >= 20)) {
      setIsValidName(false);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    } else {
      setIsValidName(true);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    }
  };

  const validateMarkaModelYearColorSeat = (
    carBrand,
    carModel,
    carYear,
    color,
    numofSeats
  ) => {
    if (carBrand === "") {
      setIsValidMarka(false);
    } else {
      setIsValidMarka(true);
      return true;
    }
    if (carModel === "") {
      setIsValidModel(false);
    } else {
      setIsValidModel(true);
      return true;
    }
    if (carYear === "") {
      setIsValidYear(false);
    } else {
      setIsValidYear(true);
      return true;
    }
    if (color === "") {
      setIsValidColor(false);
    } else {
      setIsValidColor(true);
      return true;
    }
    if (numofSeats === "") {
      setIsValidSeat(false);
    } else {
      setIsValidSeat(true);
      return true;
    }
  };
  const request = async () => {
    const fd = new FormData();
    fd.append("name", name);
    fd.append("lastname", surname);
    fd.append("mobileNum", phone);
    fd.append("gender", male ? 0 : 1);
    fd.append("birthDate", date);
    fd.append("mail", email);
    fd.append("img", fileInput !== profilpic ? fileInput : null);
    fd.append("taxiCard", taxiCardImg);
    fd.append("taxiCertificate", taxiCertificateImg);

    // fd.append('drivingLicense', drivingLicenseImg)
    // fd.append('nationalId', nationalIdImg)
    // fd.append('carLicense', carLicenseImg)
    // fd.append('carLicenseBack', carLicenseBackImg)
    // fd.append('notary', notaryImg)
    // fd.append('voen1', voenImg1)
    // fd.append('voen2', voenImg2)
    // fd.append('criminalRecord', criminalRecordImg)
    // fd.append('bank', bankImg)
    // fd.append('technical', technicalImg)
    // fd.append('medical', medicalImg)
    // fd.append('name', name)
    // fd.append('plateNumber', plateNum)
    // fd.append('maker', carBrand)
    // fd.append('model', carModel)
    // fd.append('year', carYear)
    // fd.append('seat', parseInt(numofSeats))
    // fd.append('color', color)
    const response = await BaseApi.post("/driver/register", fd);
    if (response.data.statusCode === 200) {
      // setTimeout(() => {
      //   setRegistered(true);
      // }, 2000);
      toast.success("Qeydiyyatınız uğurla nəticələndi", { autoClose: 2000 });
    } else {
      // const message = response.data.message.replace('Validation failed:')
      //  message = message.replace('')
      toast.error(response.data.message);
    }
  };

  const HandleChange = (e, num) => {
    switch (num) {
      case 1:
        setName(e.target.value);
        setIsValidName(true);
        break;
      case 2:
        setEmail(e.target.value);
        setValidationEmail(true);
        setValidationEmailExist(false);
        break;
      case 3:
        setIsValidNumber(true);
        break;
      case 4:
        setDate(e);
        setIsValidDatetime(true);
        setisValidAge(true);
        break;
      case 5:
        setSurname(e.target.value);
        setIsValidSurname(true);
        break;
      case 8:
        setMale(e.target.checked);
        setIsValidGender(true);
        break;
      case 9:
        setFemale(e.target.checked);
        setIsValidGender(true);
        break;
      case 10:
        setPin1(e.target.value);
        setIsValidPin1(true);
        break;
      case 11:
        setPin2(e.target.value);
        setIsValidPin2(true);
        break;
      // case 11:
      //   setCompanyName(e.target.value);
      //   setIsValidCompanyName(true);
      //   break;
      case 14:
        setNumOfSeats(e.target.value);
        setIsValidSeat(true);
        break;
      case 15:
        setPlateNum(e.target.value);
        setIsValidPlateNum(true);
        break;
      case 16:
        setColor(e.value);
        setIsValidColor(true);
        break;
      case 17:
        break;
      case 18:
        break;
      case 19:
        break;
      default:
        break;
    }
  };

  const handleLicense = (e) => {
    let files = e.target.files;
    setLicenseFileName(files[0].name);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setLicense(e.target.result);
    };
  };
  const handleDrivingLicenseImg = (e) => {
    let files = e.target.files;
    // handleDrivingLicenseImg(files[0].name)
    setDrivingLicenseImg(files[0]);
  };
  const handleNationalIdImg = (e) => {
    let files = e.target.files;
    setNationalIdImg(files[0]);
  };
  const handleCarLicenseImg = (e) => {
    let files = e.target.files;
    setCarLicenseImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleVoenImg1 = (e) => {
    let files = e.target.files;
    setVoenImg1(files[0]);
  };
  const handleVoenImg2 = (e) => {
    let files = e.target.files;
    setVoenImg2(files[0]);
  };
  const handleCarLicenseBackImg = (e) => {
    let files = e.target.files;
    setCarLicenseBackImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleNotaryImg = (e) => {
    let files = e.target.files;
    setNotaryImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleCriminalRecordImg = (e) => {
    let files = e.target.files;
    setCriminalRecordImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handlebankImg = (e) => {
    let files = e.target.files;
    setBankImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleTaxiCardImg = (e) => {
    let files = e.target.files;
    setTaxiCardImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleTaxiCertificateImg = (e) => {
    let files = e.target.files;
    setTaxiCertificateImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleTechnicalImg = (e) => {
    let files = e.target.files;
    setTechnicalImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const handleMedicalImg = (e) => {
    let files = e.target.files;
    setMedicalImg(files[0]);
    // setLicenseFileName(files[0].name)
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const imageHandler = (e) => {
    let files = e.target.files;
    setFileInput(files[0]);
    setFileInputDownloaded("");
    // setFileInput(files[0].name);
    // let reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // reader.onload = e => {
    // }
  };
  const HandleSubmit = () => {
    let emailValid = validateEmail(email);
    let phoneNumberValid = validatePhoneNumber(phone);
    let nameSurnameValid = validateNameSurname(name, surname);
    let datetimeValid = validationDatetime(date);
    let genderValid = validationGender(male, female);
    // let companyNameValid = validateCompanyName(companyName);
    let pinValid1 = validationPin1(voenImg1);
    let pinValid2 = validationPin2(voenImg2);
    let plateNumValid = validationPlateNum(plateNum);
    let carDetailsValid = validateMarkaModelYearColorSeat(
      carBrand,
      carModel,
      carYear,
      color,
      numofSeats
    );
    let drivingLicenseImgValidate =
      validationDrivingLicenseImg(drivingLicenseImg);
    let nationalIdImgValidate = validationNationalIdImg(nationalIdImg);
    let carLicenseImgValidate = validationCarLicenseImg(carLicenseImg);
    let carLicenseBackImgValidate =
      validationCarLicenseBackImg(carLicenseBackImg);
    let notaryImgValidate = validationNotaryImg(notaryImg);
    let criminalRecordImgValidate =
      validationCriminalRecordImg(criminalRecordImg);
    let bankImgValidate = validationBankImg(bankImg);
    let taxiCardImgValidate = validationTaxiCardImg(taxiCardImg);
    let taxiCertificateImgValidate =
      validationTaxiCertificateImg(taxiCertificateImg);
    let technicalImgValidate = validationTechnicalImg(technicalImg);
    let medicalImgValidate = validationMedicalImg(medicalImg);
    // let ImgValidate = validationImg(fileInput);
    if (
      phoneNumberValid &&
      datetimeValid &&
      nameSurnameValid &&
      genderValid &&
      taxiCardImgValidate &&
      taxiCertificateImgValidate 
    ) {
      request();
    }
    //else {
    //   if (
    //     emailValid &&
    //     phoneNumberValid &&
    //     nameSurnameValid &&
    //     datetimeValid &&
    //     genderValid &&
    //     pinValid
    //   ) {
    //     request();
    //   }
    // }
  };

  return (
    <>
      <ToastContainer />

      <Slider {...settings}>
        <div className="register-right-container 1">
          <div className="register-right-top">
            <div className="register-right-text-container">
              <h1
                className="register-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={bidLogoPurple}
                  alt="bid-logo"
                  className="bid-right-logo"
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginTop: "10px",
                    color: "#6F52EA",
                    fontWeight: "700",
                  }}
                >
                  Sürücü
                </span>
              </h1>
              <p className="welcome">Xoş Gəlmişsiniz!</p>
            </div>
            <label htmlFor="file-input" className="profile-photo">
              {/* <img
                style={{
                  border:
                    fileInputDownloaded.length && fileInputDownloaded === "0"
                      ? "1px solid red"
                      : "1px solid transparent",
                }}
                className="profil-pic"
                src={
                  fileInput !== profilpic
                    ? URL.createObjectURL(fileInput)
                    : fileInput
                }
                alt="profile-pic"
              />
              <div className="add-sign upload">+</div>
              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={(e) => imageHandler(e)}
              /> */}
            </label>
          </div>
          {/* {fileInputDownloaded.length && fileInputDownloaded === "0" ? (
            <div className="error-message">Şəkil yükləyin</div>
          ) : (
            <></>
          )} */}
          <form
            className="form-register"
            onSubmit={(e) => {
              e.preventDefault();
              HandleSubmit();
            }}
          >
            <div className="form-register-inputs">
              <div className="register-field-two-inputs">
                <div className="register-field name">
                  <label htmlFor="name">Ad *məcburi</label>
                  <input
                    placeholder="Ad"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => HandleChange(e, 1)}
                  />
                  {!isValidName ? (
                    <div className="error-message">
                      Ad ən az 2 və ən çox 20 simvol ola bilər
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="register-field surname">
                  <label htmlFor="surname">Soyad *məcburi</label>
                  <input
                    placeholder="Soyad"
                    type="text"
                    id="surname"
                    value={surname}
                    onChange={(e) => HandleChange(e, 5)}
                  />
                  {!isValidSurname ? (
                    <div className="error-message">
                      Soyad ən az 2 və ən çox 20 simvol ola bilər
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="register-field datetime">
                <label htmlFor="datetime">Doğum tarixi *məcburi</label>
                {/* <input
                  type="date"
                  id="datetime"
                  value={date}
                  placeholder="dd-mm-yyyy"
                  onChange={(e) => HandleChange(e.target.value, 4)}
                  min="2017-12-31"
                  max={new Date()}
                /> */}
                <DatePicker
                  onChange={(e) => HandleChange(e, 4)}
                  value={date}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  // locale="az"
                  format="d MMMM yyyy"
                />
              </div>
              {!isValidDatetime ? (
                <div className="error-message">Tarix qeyd olunmalıdır</div>
              ) : (
                <></>
              )}
              {!isValidAge ? (
                <div className="error-message">
                  Aşağı yaş həddi 15 və yuxarı yaş həddi 91 olmalıdır
                </div>
              ) : (
                <></>
              )}
              <div className="gender-inputs">
                <label htmlFor="gender">Cins *məcburi</label>
                <div className="radio-btns">
                  <div className="form-radio-group">
                    <input
                      type="radio"
                      name="size"
                      id="kisi"
                      className="form-radio-input"
                      onChange={(e) => HandleChange(e, 8)}
                    />
                    <label
                      className="form-label-radio form-radio-label"
                      htmlFor="kisi"
                    >
                      <span className="form-radio-button"></span>
                      Kişi
                    </label>
                  </div>
                  <div className="form-radio-group">
                    <input
                      type="radio"
                      name="size"
                      id="qadin"
                      className="form-radio-input"
                      onChange={(e) => HandleChange(e, 9)}
                    />
                    <label
                      htmlFor="qadin"
                      className="form-label-radio form-radio-label"
                    >
                      <span className="form-radio-button"></span> Qadın
                    </label>
                  </div>
                </div>
              </div>
              {!isValidGender ? (
                <div className="error-message">Cinsiyyət seçilməlidir</div>
              ) : (
                <></>
              )}
              <div className="register-field phone">
                <label htmlFor="phone">Telefon *məcburi</label>
                <PhoneInput
                  className="phone-input"
                  id="phone"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="AZ"
                  value={phone}
                  onChange={setPhone}
                />
                <span className="country-code">+994</span>
              </div>
              {!isValidNumber ? (
                <div className="error-message">Nömrə düzgün deyil</div>
              ) : (
                <></>
              )}
              <div className="register-field email">
                <label htmlFor="email">E-poçt</label>
                <input
                  placeholder="Email"
                  type="text"
                  id="email"
                  value={email}
                  onChange={(e) => HandleChange(e, 2)}
                />
              </div>
              {/* <span> Nəqliyyat markası*</span>
              <div className={'search '}>
                <div className='searchbar'>
                  <input
                    className='search-input'
                    onChange={e => {
                      onSearchCarBrand(e.target.value)
                    }}
                    value={carBrand}
                    type='text'
                    placeholder='Nəqliyyat markası?'
                  />
                  <span
                    onClick={() => {
                      setCarListVisible(!carListVisible)
                    }}
                  >
                    {searchIcon}
                  </span>
                </div>

                <div className='filtered-results'>
                  {carListVisible && [
                    ...carFiltered.map((elem, index) => {
                      return (
                        <div
                          className='searched-service'
                          onClick={() => selectCarName(carFiltered[index])}
                        >
                          <p>{elem.title}</p>
                        </div>
                      )
                    })
                  ]}
                </div>
              </div>
              {!isValidMarka ? (
                <div className='error-message'>Marka seçilməlidir</div>
              ) : (
                <></>
              )}
              <span> Nəqliyyat vasitəsinin modeli*</span>

              <div className='search '>
                <div className='searchbar'>
                  <input
                    className='search-input'
                    onChange={e => {
                      onSearchCarModel(e.target.value)
                    }}
                    value={carModel}
                    type='text'
                    placeholder='Nəqliyyat vasitəsinin modeli?'
                  />
                  <span
                    onClick={() => {
                      setCarModelListVisible(!carModelListVisible)
                    }}
                  >
                    {searchIcon}
                  </span>
                </div>
                <div className='filtered-results'>
                  {carModelListVisible && [
                    ...carModelFiltered.map((elem, index) => {
                      return (
                        <div
                          className='searched-service'
                          onClick={() =>
                            selectCarModel(carModelFiltered[index])
                          }
                        >
                          <p>{elem.title}</p>
                        </div>
                      )
                    })
                  ]}
                </div>
              </div>
              {!isValidModel ? (
                <div className='error-message'>Model seçilməlidir</div>
              ) : (
                <></>
              )}
              <span> Nəqliyyat vasitəsinin ili* </span>

              <div className='search '>
                <div className='searchbar'>
                  <input
                    className='search-input'
                    onChange={e => {
                      onSearchCarYear(e.target.value)
                    }}
                    value={carYear}
                    type='text'
                    placeholder='İl seçərək daxil edin'
                  />
                  <span
                    onClick={() => {
                      setCarYearListVisible(!carYearListVisible)
                    }}
                  >
                    {searchIcon}
                  </span>
                </div>
                <div className='filtered-results'>
                  {carYearListVisible && [
                    ...carYearFiltered.map((elem, index) => {
                      return (
                        <div
                          className='searched-service'
                          onClick={() => selectCarYear(carYearFiltered[index])}
                        >
                          <p>{elem.title}</p>
                        </div>
                      )
                    })
                  ]}
                </div>
              </div>
              {!isValidYear ? (
                <div className='error-message'>İl seçilməlidir</div>
              ) : (
                <></>
              )}

              <div className='register-field color'>
                <label htmlFor='color'>Nəqliyyat rəngi*</label>
                <Select
                  //  value={color}
                  // placeholder="Bronze"
                  styles={customStyles}
                  options={options}
                  onChange={e => HandleChange(e, 16)}
                />
              </div>
              {!isValidColor ? (
                <div className='error-message'>Rəng seçilməlidir</div>
              ) : (
                <></>
              )}

              <div className='register-field reg-num'>
                <label htmlFor='plateNum'>Dövlət qeydiyyat nömrəsi* </label>
                <input
                  placeholder='XX/XX/XXX'
                  type='text'
                  id='plateNum'
                  value={plateNum}
                  onChange={e => HandleChange(e, 15)}
                />
              </div>
              {!isValidPlateNum ? (
                <div className='error-message'>seriya nömrəsini daxil edin</div>
              ) : (
                <></>
              )}
              <div className='register-field reg-num'>
                <label htmlFor='seatNumber'>Oturacaq sayı*</label>
                <input
                  type='number'
                  id='seatNumber'
                  value={numofSeats}
                  onChange={e => HandleChange(e, 14)}
                />
              </div>
              {!isValidSeat ? (
                <div className='error-message'>Oturacaq sayını daxil edin</div>
              ) : (
                <></>
              )} */}
              {/* <div className='register-field pin'>
                <label htmlFor='pin'>Vöen 1</label>
                <input
                  type='number'
                  id='pin'
                  // max={10}
                  // min={0}
                  value={pin1}
                  onChange={e => HandleChange(e, 10)}
                />
              </div>
              {!isValidPin1 ? (
                <div className='error-message'>
                  Vöen 10 simvoldan ibarət olmalıdır
                </div>
              ) : (
                <></>
              )} */}

              {/* <div className='register-field email'>
                <label htmlFor='voen1'>Vöen 1-ci səhifə</label>
                <input
                  placeholder='Vöen 1-ci səhifə'
                  type='text'
                  id='voen1'
                  value={voenImg1}
                  onChange={e => setVoenImg1(e.target.value)}
                />
              </div>
              {!voenImg1.length && voenImg1 === '' ? (
                <div className='error-message'>Vöen 1-ci səhifə qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='voen2'>Vöen 2-ci səhifə</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='voen2'
                  value={voenImg2}
                  onChange={e => setVoenImg2(e.target.value)}
                />
              </div>
              {!voenImg2.length && voenImg2 === '' ? (
                <div className='error-message'>Vöen 2-ci səhifə qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='driverİd'>Sürücülük vəsiqəsi</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='driverİd'
                  value={drivingLicenseImg}
                  onChange={e => setDrivingLicenseImg(e.target.value)}
                />
              </div>
              {!drivingLicenseImg.length && drivingLicenseImg === '' ? (
                <div className='error-message'>Sürücülük vəsiqəsi qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='nationalId'>Şəxsiyyət vəsiqəsi</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='nationalId'
                  value={nationalIdImg}
                  onChange={e => setNationalIdImg(e.target.value)}
                />
              </div>
              {!nationalIdImg.length && nationalIdImg === '' ? (
                <div className='error-message'>Şəxsiyyət vəsiqəsi qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='carLicense'>Texpasport Üz tərəfi</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='carLicense'
                  value={carLicenseImg}
                  onChange={e => setCarLicenseImg(e.target.value)}
                />
              </div>
              {!carLicenseImg.length && carLicenseImg === '' ? (
                <div className='error-message'>Texpasport Üz tərəfi qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='carLicenseBack'>Texpasport Arxa tərəfi</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='carLicenseBack'
                  value={carLicenseBackImg}
                  onChange={e => setCarLicenseBackImg(e.target.value)}
                />
              </div>
              {!carLicenseBackImg.length && carLicenseBackImg === '' ? (
                <div className='error-message'>Texpasport Arxa tərəfi qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='notary'>Etibarnamə</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='notary'
                  value={notaryImg}
                  onChange={e => setNotaryImg(e.target.value)}
                />
              </div>
              {!notaryImg.length && notaryImg === '' ? (
                <div className='error-message'>Etibarnamə qeyd edilməlidir.</div>
              ) : (
                <></>
              )}

              <div className='register-field email'>
                <label htmlFor='criminalRecordImg'>Məhkumluq</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='criminalRecordImg'
                  value={criminalRecordImg}
                  onChange={e => setCriminalRecordImg(e.target.value)}
                />
              </div>
              {!criminalRecordImg.length && criminalRecordImg === '' ? (
                <div className='error-message'>Məhkumluq qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='bankImg'>Bank rekviziti</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='bankImg'
                  value={bankImg}
                  onChange={e => setBankImg(e.target.value)}
                />
              </div>
              {!bankImg.length && bankImg === '' ? (
                <div className='error-message'>Bank rekviziti qeyd edilməlidir.</div>
              ) : (
                <></>
              )} */}
              <div className="register-field email">
                <label htmlFor="taxiCardImg">Buraxılış kartı *məcburi</label>
                <input
                  placeholder="Vöen 2-ci səhifə"
                  type="text"
                  id="taxiCardImg"
                  value={taxiCardImg}
                  onChange={(e) => setTaxiCardImg(e.target.value)}
                />
              </div>
              {!taxiCardImg.length && taxiCardImg === "" ? (
                <div className="error-message">
                  Buraxılış kartı qeyd edilməlidir.
                </div>
              ) : (
                <></>
              )}
              <div className="register-field email">
                <label htmlFor="taxiCertificateImg">
                  Buraxılış vəsiqəsi *məcburi
                </label>
                <input
                  placeholder="Vöen 2-ci səhifə"
                  type="text"
                  id="taxiCertificateImg"
                  value={taxiCertificateImg}
                  onChange={(e) => setTaxiCertificateImg(e.target.value)}
                />
              </div>
              {!taxiCertificateImg.length && taxiCertificateImg === "" ? (
                <div className="error-message">
                  Buraxılış vəsiqəsi qeyd edilməlidir.
                </div>
              ) : (
                <></>
              )}
              {/* <div className='register-field email'>
                <label htmlFor='technicalImg'>Texniki Müqavilə *məcburi</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='technicalImg'
                  value={technicalImg}
                  onChange={e => setTechnicalImg(e.target.value)}
                />
              </div>
              {!technicalImg.length && technicalImg === '' ? (
                <div className='error-message'>Texniki Müqavilə qeyd edilməlidir.</div>
              ) : (
                <></>
              )}
              <div className='register-field email'>
                <label htmlFor='medicalImg'>Tibbi Müqavilə *məcburi</label>
                <input
                  placeholder='Vöen 2-ci səhifə'
                  type='text'
                  id='medicalImg'
                  value={medicalImg}
                  onChange={e => setMedicalImg(e.target.value)}
                />
              </div>
              {!medicalImg.length && medicalImg === '' ? (
                <div className='error-message'>Tibbi Müqavilə qeyd edilməlidir.</div>
              ) : (
                <></>
              )} */}
            </div>
            <div
              className="register-buttons"
              style={{
                bottom: "0",
              }}
            >
              <button className="create-acc" type="submit">
                Qeydiyyatdan keç
              </button>
            </div>
          </form>
        </div>
      </Slider>
    </>
  );
}
