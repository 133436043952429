import React from "react";
import Navbar from "./Navbar";
import "../assets/styles/banner.css";
import bidPurple from "../assets/images/BiD-LOQO-BCKGR.svg";
import playStore from "../assets/images/google_play.svg";
import appStore from "../assets/images/footer.svg";
import bidLogoOnText from "../assets/images/bidLogoOnText.svg";
import mouse from "../assets/images/mouse.svg";
import UserCard from "./UserCard";
import DriverCard from "./DriverCard";
import DriverCardDesktop from "./DriverCardDesktop";
import UserCardDesktop from "./UserCardDesktop";

function BannerDesktop({ btnRef }) {
  const goToCategories = () => {
    window.scrollTo({ top: btnRef.current.offsetTop, behavior: "smooth" });
  };

  return (
    <>
      <div className="banner-content">
        <div className="user-driver-cards-part" style={{
          backgroundcolor:'black'
        }}>
          <DriverCardDesktop />
          <UserCardDesktop />
        </div>
      </div>
    </>
  );
}

export default BannerDesktop;

{
  /* <div className="banner-content">
  <div className="banner-left">
    <div className="banner-texts">
      <h1 className="banner-heading">
        Yolçu
        <div>
          <span>bid</span>
        </div>
        -də gərək!
      </h1>
      <p className="banner-small-text">
        Sizin rahatlığınızı və büdcənizi düşünən bir tətbiq
      </p>
      <div className="social-links">
        <img className="stores-logo" src={playStore} alt="play store" />
        <img className="stores-logo" src={appStore} alt="app store" />
      </div>
      <div className="scroll-down" onClick={goToCategories}>
        <img src={mouse} alt="scroll-bar-icon" className="scroll-down-mouse" />
      </div>
    </div>
  </div>
  <div className="banner-right">
    <div className="banner-right-image"></div>
  </div>
</div>; */
}
