import React, { useState, useEffect } from "react";
import "../assets/styles/register.css";
import bidLogoWhite from "../assets/images/bid-logo-wh.svg";
import Slider from "../components/SliderFormFleet";
import RegisterPagePopUpFleet from "../components/RegisterPagePopUpFleet";
import Navbar from "../components/Navbar";

function RegisterFleet() {
  return (
    <>
      <Navbar />
      <div className="register-container" style={{
        marginTop:'60px'
      }}>
        <div className="register-left register-left-fleet">
      </div>
        <div className="register-right">
          <Slider />
        </div>
      </div>
    </>
  );
}

export default RegisterFleet;
