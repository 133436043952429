import axios from "axios";

// const BaseApi = axios.create({
//   baseURL: "http://80.241.208.93:5100/api",
//   headers: {
//     "Content-Type": "application/json; charset=utf-8",
//     "Access-Control-Allow-Origin": "*",
//   },
// });
const BaseApi = axios.create({
  baseURL: "https://api.bid.az/",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});
export default BaseApi;
