export const data = [
  {
    "Make": "Acura",
    "Details": [
      {
        "ModelName": "CL",
        "Year": [
          1997,
          1998,
          1999,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "ILX",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Integra",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Legend",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "MDX",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2022
        ]
      },
      {
        "ModelName": "MDX Sport Hybrid",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "NSX",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "RDX",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "RL",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "RLX",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "RLX Sport Hybrid",
        "Year": [
          2014,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "RSX",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "SLX",
        "Year": [
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "TL",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "TLX",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "TSX",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Vigor",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "ZDX",
        "Year": [
          2010,
          2011,
          2012,
          2013
        ]
      }
    ]
  },
  {
    "Make": "Alfa Romeo",
    "Details": [
      {
        "ModelName": "164",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "4C",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "4C Spider",
        "Year": [
          2015,
          2016,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Giulia",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Spider",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Stelvio",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Aston Martin",
    "Details": [
      {
        "ModelName": "DB11",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "DB9",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "DB9 GT",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "DBS",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Rapide",
        "Year": [
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Rapide S",
        "Year": [
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Vanquish",
        "Year": [
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Vanquish S",
        "Year": [
          2005,
          2006,
          2018
        ]
      },
      {
        "ModelName": "Vantage",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Virage",
        "Year": [
          2012
        ]
      }
    ]
  },
  {
    "Make": "Audi",
    "Details": [
      {
        "ModelName": "100",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "80",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "90",
        "Year": [
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "A3",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "A3 Sportback e-tron",
        "Year": [
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "A4",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "A4 (2005.5)",
        "Year": [
          2005
        ]
      },
      {
        "ModelName": "A4 allroad",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "A5",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2018,
          2019
        ]
      },
      {
        "ModelName": "A5 Sport",
        "Year": [
          2017
        ]
      },
      {
        "ModelName": "A6",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "A6 allroad",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "A7",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "A8",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Cabriolet",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Q3",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Q5",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Q7",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Q8",
        "Year": [
          2019,
          2021
        ]
      },
      {
        "ModelName": "Quattro",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "R8",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2014,
          2015,
          2017,
          2018,
          2020
        ]
      },
      {
        "ModelName": "RS 3",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "RS 4",
        "Year": [
          2007,
          2008
        ]
      },
      {
        "ModelName": "RS 5",
        "Year": [
          2013,
          2014,
          2015,
          2018,
          2019
        ]
      },
      {
        "ModelName": "RS 6",
        "Year": [
          2003
        ]
      },
      {
        "ModelName": "RS 7",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "S3",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "S4",
        "Year": [
          1992,
          1993,
          1994,
          2000,
          2001,
          2002,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2018,
          2019
        ]
      },
      {
        "ModelName": "S4 (2005.5)",
        "Year": [
          2005
        ]
      },
      {
        "ModelName": "S5",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "S6",
        "Year": [
          1995,
          2002,
          2003,
          2007,
          2008,
          2009,
          2010,
          2011,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2021
        ]
      },
      {
        "ModelName": "S7",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "S8",
        "Year": [
          2001,
          2002,
          2003,
          2007,
          2008,
          2009,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "SQ5",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "TT",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "allroad",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "e-tron",
        "Year": [
          2019
        ]
      }
    ]
  },
  {
    "Make": "BMW",
    "Details": [
      {
        "ModelName": "1 Series",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "2 Series",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "3 Series",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "4 Series",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "5 Series",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "6 Series",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "7 Series",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "8 Series",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          2019
        ]
      },
      {
        "ModelName": "Alpina B7",
        "Year": [
          2007,
          2008
        ]
      },
      {
        "ModelName": "M",
        "Year": [
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "M2",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "M3",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "M4",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "M5",
        "Year": [
          1992,
          1993,
          2000,
          2001,
          2002,
          2003,
          2006,
          2007,
          2008,
          2009,
          2010,
          2013,
          2014,
          2015,
          2016,
          2018,
          2019
        ]
      },
      {
        "ModelName": "M6",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "X1",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "X2",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "X3",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "X4",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "X5",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "X5 M",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "X6",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "X6 M",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "X7",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Z3",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Z4",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2019
        ]
      },
      {
        "ModelName": "Z4 M",
        "Year": [
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Z8",
        "Year": [
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "i3",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "i8",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2019
        ]
      }
    ]
  },
  {
    "Make": "Bentley",
    "Details": [
      {
        "ModelName": "Arnage",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Azure",
        "Year": [
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Azure T",
        "Year": [
          2010
        ]
      },
      {
        "ModelName": "Bentayga",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Brooklands",
        "Year": [
          2009,
          2010
        ]
      },
      {
        "ModelName": "Continental",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Flying Spur",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Mulsanne",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      }
    ]
  },
  {
    "Make": "Buick",
    "Details": [
      {
        "ModelName": "Cascada",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Century",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Enclave",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Encore",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Encore GX",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Envision",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "LaCrosse",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "LeSabre",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Lucerne",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Park Avenue",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Rainier",
        "Year": [
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Regal",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Regal Sportback",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "Regal TourX",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "Rendezvous",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Riviera",
        "Year": [
          1992,
          1993,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Roadmaster",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Skylark",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Terraza",
        "Year": [
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Verano",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      }
    ]
  },
  {
    "Make": "Cadillac",
    "Details": [
      {
        "ModelName": "ATS",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "ATS-V",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Allante",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Brougham",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "CT4",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "CT5",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "CT6",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "CT6-V",
        "Year": [
          2019,
          2020
        ]
      },
      {
        "ModelName": "CTS",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "CTS-V",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Catera",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "DTS",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "DeVille",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "ELR",
        "Year": [
          2014,
          2016
        ]
      },
      {
        "ModelName": "Eldorado",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Escalade",
        "Year": [
          1999,
          2000,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Escalade ESV",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Escalade EXT",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Fleetwood",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "SRX",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "STS",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Seville",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Sixty Special",
        "Year": [
          1993
        ]
      },
      {
        "ModelName": "XLR",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "XT4",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "XT5",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "XT6",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "XTS",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      }
    ]
  },
  {
    "Make": "Chevrolet",
    "Details": [
      {
        "ModelName": "1500 Extended Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "1500 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "2500 Crew Cab",
        "Year": [
          1999,
          2000
        ]
      },
      {
        "ModelName": "2500 Extended Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "2500 HD Extended Cab",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "2500 HD Regular Cab",
        "Year": [
          1999,
          2000
        ]
      },
      {
        "ModelName": "2500 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "3500 Crew Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "3500 Extended Cab",
        "Year": [
          1992,
          1993,
          1994,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "3500 HD Extended Cab",
        "Year": [
          1995
        ]
      },
      {
        "ModelName": "3500 HD Regular Cab",
        "Year": [
          1995
        ]
      },
      {
        "ModelName": "3500 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "APV Cargo",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Astro Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Astro Passenger",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Avalanche",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Avalanche 1500",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Avalanche 2500",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Aveo",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Beretta",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Blazer",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Bolt EV",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Camaro",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Caprice",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Caprice Classic",
        "Year": [
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Captiva Sport",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Cavalier",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "City Express",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Classic",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "Cobalt",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Colorado Crew Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Colorado Extended Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Colorado Regular Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Corsica",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Corvette",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Cruze",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Cruze Limited",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "Equinox",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Express 1500 Cargo",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Express 1500 Passenger",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Express 2500 Cargo",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Express 2500 Passenger",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Express 3500 Cargo",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Express 3500 Passenger",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "G-Series 1500",
        "Year": [
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "G-Series 2500",
        "Year": [
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "G-Series 3500",
        "Year": [
          1997,
          1998
        ]
      },
      {
        "ModelName": "G-Series G10",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "G-Series G20",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "G-Series G30",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "HHR",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Impala",
        "Year": [
          1994,
          1995,
          1996,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Impala Limited",
        "Year": [
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Lumina",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Lumina APV",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Lumina Cargo",
        "Year": [
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Lumina Passenger",
        "Year": [
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Malibu",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Malibu (Classic)",
        "Year": [
          2008
        ]
      },
      {
        "ModelName": "Malibu Limited",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "Metro",
        "Year": [
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Monte Carlo",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Prizm",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "S10 Blazer",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "S10 Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "S10 Extended Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "S10 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "SS",
        "Year": [
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "SSR",
        "Year": [
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Silverado (Classic) 1500 Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 1500 Extended Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 1500 HD Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 1500 Regular Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 2500 HD Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 2500 HD Extended Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 2500 HD Regular Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 3500 Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 3500 Extended Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado (Classic) 3500 Regular Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Silverado 1500 Crew Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Silverado 1500 Double Cab",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Silverado 1500 Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Silverado 1500 HD Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Silverado 1500 LD Double Cab",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "Silverado 1500 Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Silverado 2500 Crew Cab",
        "Year": [
          2004
        ]
      },
      {
        "ModelName": "Silverado 2500 Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Silverado 2500 HD Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Silverado 2500 HD Double Cab",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Silverado 2500 HD Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Silverado 2500 HD Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Silverado 2500 Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Silverado 3500 Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Silverado 3500 Extended Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Silverado 3500 HD Crew Cab",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Silverado 3500 HD Double Cab",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Silverado 3500 HD Extended Cab",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Silverado 3500 HD Regular Cab",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Silverado 3500 Regular Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Sonic",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Spark",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Spark EV",
        "Year": [
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Sportvan G10",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Sportvan G20",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Sportvan G30",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Suburban",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Suburban 1500",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Suburban 2500",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Suburban 3500HD",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Tahoe",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Tahoe (New)",
        "Year": [
          2000
        ]
      },
      {
        "ModelName": "Tracker",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "TrailBlazer",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Trailblazer",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Traverse",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Trax",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Uplander Cargo",
        "Year": [
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Uplander Passenger",
        "Year": [
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Venture Cargo",
        "Year": [
          1998,
          1999,
          2000,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Venture Passenger",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Volt",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Onix",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      }
    ]
  },
  {
    "Make": "Chrysler",
    "Details": [
      {
        "ModelName": "200",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "300",
        "Year": [
          1999,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "300M",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Aspen",
        "Year": [
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Cirrus",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Concorde",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Crossfire",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Fifth Ave",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Grand Voyager",
        "Year": [
          2000
        ]
      },
      {
        "ModelName": "Imperial",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "LHS",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "LeBaron",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "New Yorker",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "PT Cruiser",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Pacifica",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Pacifica Hybrid",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Prowler",
        "Year": [
          2001,
          2002
        ]
      },
      {
        "ModelName": "Sebring",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Town & Country",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Voyager",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Daewoo",
    "Details": [
      {
        "ModelName": "Lanos",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Leganza",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Nubira",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Arcadia",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Damas",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Espero",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Evanda",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Gentra",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Imperial",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Kalos",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Labo",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Lacetti",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "LeMans",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Lublin",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Magnus",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Matiz",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Musso",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Neixa",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Prince",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Rezzo",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Royale",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Statesman",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Tacuma",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Tico",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Tosca",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Veritas",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Winstorm",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Winstorm MaXX",
        "Year": [
          2024
        ]
      }
    ]
  },
  {
    "Make": "Daihatsu",
    "Details": [
      {
        "ModelName": "Charade",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Rocky",
        "Year": [
          1992
        ]
      }
    ]
  },
  {
    "Make": "Dodge",
    "Details": [
      {
        "ModelName": "Avenger",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Caliber",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Caravan Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Caravan Passenger",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Challenger",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Charger",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Colt",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "D150 Club Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "D150 Regular Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "D250 Club Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "D250 Regular Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "D350 Club Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "D350 Regular Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Dakota Club Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Dakota Crew Cab",
        "Year": [
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Dakota Extended Cab",
        "Year": [
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Dakota Quad Cab",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Dakota Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Dart",
        "Year": [
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Daytona",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Durango",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Dynasty",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Grand Caravan Cargo",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Grand Caravan Passenger",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Intrepid",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Journey",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Magnum",
        "Year": [
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Monaco",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Neon",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Nitro",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Ram 1500 Club Cab",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Ram 1500 Crew Cab",
        "Year": [
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 1500 Mega Cab",
        "Year": [
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Ram 1500 Quad Cab",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 1500 Regular Cab",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 2500 Club Cab",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Ram 2500 Crew Cab",
        "Year": [
          2010
        ]
      },
      {
        "ModelName": "Ram 2500 Mega Cab",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 2500 Quad Cab",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Ram 2500 Regular Cab",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 3500 Club Cab",
        "Year": [
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Ram 3500 Crew Cab",
        "Year": [
          2010
        ]
      },
      {
        "ModelName": "Ram 3500 Mega Cab",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 3500 Quad Cab",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Ram 3500 Regular Cab",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Ram 50 Regular Cab",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Ram Van 1500",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Ram Van 2500",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Ram Van 3500",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Ram Van B150",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ram Van B250",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ram Van B350",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ram Wagon 1500",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Ram Wagon 2500",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Ram Wagon 3500",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Ram Wagon B150",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ram Wagon B250",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ram Wagon B350",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ramcharger",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Shadow",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Spirit",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Sprinter 2500 Cargo",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Sprinter 2500 Passenger",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Sprinter 3500 Cargo",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Stealth",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Stratus",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Viper",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2008,
          2009,
          2010,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Attitude",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Hornet",
        "Year": [
          2023
        ]
      }
    ]
  },
  {
    "Make": "Eagle",
    "Details": [
      {
        "ModelName": "Premier",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Summit",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Talon",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Vision",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      }
    ]
  },
  {
    "Make": "FIAT",
    "Details": [
      {
        "ModelName": "124 Spider",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "500",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "500 Abarth",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "500L",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "500X",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "500c",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "500c Abarth",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "500e",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      }
    ]
  },
  {
    "Make": "Ferrari",
    "Details": [
      {
        "ModelName": "430 Scuderia",
        "Year": [
          2008,
          2009
        ]
      },
      {
        "ModelName": "458 Italia",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "458 Speciale",
        "Year": [
          2014,
          2015
        ]
      },
      {
        "ModelName": "458 Spider",
        "Year": [
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "488 GTB",
        "Year": [
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "488 Spider",
        "Year": [
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "599 GTB Fiorano",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "599 GTO",
        "Year": [
          2011
        ]
      },
      {
        "ModelName": "612 Scaglietti",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "812 Superfast",
        "Year": [
          2018
        ]
      },
      {
        "ModelName": "California",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "F12berlinetta",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "F430",
        "Year": [
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "FF",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "GTC4Lusso",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Portofino",
        "Year": [
          2018
        ]
      }
    ]
  },
  {
    "Make": "Fisker",
    "Details": [
      {
        "ModelName": "Karma",
        "Year": [
          2012
        ]
      }
    ]
  },
  {
    "Make": "Ford",
    "Details": [
      {
        "ModelName": "Aerostar Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Aerostar Passenger",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Aspire",
        "Year": [
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Bronco",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Bronco Sport",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "C-MAX Energi",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "C-MAX Hybrid",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Club Wagon",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Contour",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Crown Victoria",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "E150 Cargo",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "E150 Passenger",
        "Year": [
          2003,
          2004,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "E150 Super Duty Cargo",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "E150 Super Duty Passenger",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "E250 Cargo",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "E250 Super Duty Cargo",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "E350 Super Duty Cargo",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "E350 Super Duty Passenger",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "EcoSport",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Econoline E150 Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Econoline E150 Passenger",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Econoline E250 Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Econoline E350 Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Econoline E350 Super Duty Cargo",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Econoline E350 Super Duty Passenger",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Edge",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Escape",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Escort",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Excursion",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Expedition",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Expedition EL",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Expedition MAX",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Explorer",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Explorer Sport",
        "Year": [
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Explorer Sport Trac",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "F150 (Heritage) Regular Cab",
        "Year": [
          2004
        ]
      },
      {
        "ModelName": "F150 (Heritage) Super Cab",
        "Year": [
          2004
        ]
      },
      {
        "ModelName": "F150 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "F150 Super Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "F150 SuperCrew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "F250 Crew Cab",
        "Year": [
          1996,
          1997
        ]
      },
      {
        "ModelName": "F250 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "F250 Super Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "F250 Super Duty Crew Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "F250 Super Duty Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "F250 Super Duty Super Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "F350 Crew Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "F350 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "F350 Super Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "F350 Super Duty Crew Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "F350 Super Duty Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "F350 Super Duty Super Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "F450 Super Duty Crew Cab",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Festiva",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Fiesta",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Five Hundred",
        "Year": [
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Flex",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Focus",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Focus ST",
        "Year": [
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Freestar Cargo",
        "Year": [
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Freestar Passenger",
        "Year": [
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Freestyle",
        "Year": [
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Fusion",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Fusion Energi",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "GT",
        "Year": [
          2005,
          2006
        ]
      },
      {
        "ModelName": "Mustang",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Probe",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Ranger Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Ranger Super Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Ranger SuperCab",
        "Year": [
          2019,
          2020
        ]
      },
      {
        "ModelName": "Ranger SuperCrew",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Taurus",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Taurus X",
        "Year": [
          2008,
          2009
        ]
      },
      {
        "ModelName": "Tempo",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Thunderbird",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Transit 150 Van",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Transit 150 Wagon",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Transit 250 Van",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Transit 350 HD Van",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Transit 350 Van",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Transit 350 Wagon",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Transit Connect Cargo",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Transit Connect Cargo Van",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Transit Connect Passenger",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Windstar Cargo",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Windstar Passenger",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "ZX2",
        "Year": [
          2001,
          2002,
          2003
        ]
      }
    ]
  },
  {
    "Make": "Freightliner",
    "Details": [
      {
        "ModelName": "Sprinter 2500 Cargo",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Sprinter 2500 Crew",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter 2500 Passenger",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter 3500 Cargo",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter 3500XD Cargo",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter WORKER Cargo",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter WORKER Passenger",
        "Year": [
          2017
        ]
      }
    ]
  },
  {
    "Make": "GMC",
    "Details": [
      {
        "ModelName": "1500 Club Coupe",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "1500 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "2500 Club Coupe",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "2500 Crew Cab",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "2500 HD Club Coupe",
        "Year": [
          1997,
          1998
        ]
      },
      {
        "ModelName": "2500 HD Extended Cab",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "2500 HD Regular Cab",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "2500 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "3500 Club Coupe",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "3500 Crew Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "3500 Extended Cab",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "3500 Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Acadia",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Acadia Limited",
        "Year": [
          2017
        ]
      },
      {
        "ModelName": "Canyon Crew Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Canyon Extended Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Canyon Regular Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Envoy",
        "Year": [
          1998,
          1999,
          2000,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Envoy XL",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Envoy XUV",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "Hummer EV",
        "Year": [
          2022
        ]
      },
      {
        "ModelName": "Jimmy",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Rally Wagon 1500",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Rally Wagon 2500",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Rally Wagon 3500",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Rally Wagon G2500",
        "Year": [
          1995
        ]
      },
      {
        "ModelName": "Rally Wagon G3500",
        "Year": [
          1995,
          1996
        ]
      },
      {
        "ModelName": "Safari Cargo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Safari Passenger",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Savana 1500 Cargo",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Savana 1500 Passenger",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Savana 2500 Cargo",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Savana 2500 Passenger",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Savana 3500 Cargo",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Savana 3500 Passenger",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sierra (Classic) 1500 Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 1500 Extended Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 1500 HD Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 1500 Regular Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 2500 Crew Cab",
        "Year": [
          2000
        ]
      },
      {
        "ModelName": "Sierra (Classic) 2500 HD Crew Cab",
        "Year": [
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 2500 HD Extended Cab",
        "Year": [
          2000,
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 2500 HD Regular Cab",
        "Year": [
          2000,
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 3500 Crew Cab",
        "Year": [
          2000,
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 3500 Extended Cab",
        "Year": [
          2000,
          2007
        ]
      },
      {
        "ModelName": "Sierra (Classic) 3500 Regular Cab",
        "Year": [
          2000,
          2007
        ]
      },
      {
        "ModelName": "Sierra 1500 Crew Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Sierra 1500 Double Cab",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Sierra 1500 Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Sierra 1500 HD Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Sierra 1500 Limited Double Cab",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "Sierra 1500 Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Sierra 2500 Crew Cab",
        "Year": [
          2004
        ]
      },
      {
        "ModelName": "Sierra 2500 Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Sierra 2500 HD Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Sierra 2500 HD Double Cab",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Sierra 2500 HD Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Sierra 2500 HD Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Sierra 2500 Regular Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Sierra 3500 Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Sierra 3500 Extended Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Sierra 3500 HD Crew Cab",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Sierra 3500 HD Double Cab",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sierra 3500 HD Extended Cab",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Sierra 3500 HD Regular Cab",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sierra 3500 Regular Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Sonoma Club Cab",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Sonoma Club Coupe Cab",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Sonoma Crew Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Sonoma Extended Cab",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Sonoma Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Suburban 1500",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Suburban 2500",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Terrain",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Vandura 1500",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Vandura 2500",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Vandura 3500",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Vandura G1500",
        "Year": [
          1995
        ]
      },
      {
        "ModelName": "Vandura G2500",
        "Year": [
          1995
        ]
      },
      {
        "ModelName": "Vandura G3500",
        "Year": [
          1995,
          1996
        ]
      },
      {
        "ModelName": "Yukon",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Yukon Denali",
        "Year": [
          2000
        ]
      },
      {
        "ModelName": "Yukon XL",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Yukon XL 1500",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Yukon XL 2500",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      }
    ]
  },
  {
    "Make": "Genesis",
    "Details": [
      {
        "ModelName": "G70",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "G80",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "G90",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "GV80",
        "Year": [
          2021
        ]
      }
    ]
  },
  {
    "Make": "Geo",
    "Details": [
      {
        "ModelName": "Metro",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Prizm",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Storm",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Tracker",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      }
    ]
  },
  {
    "Make": "HUMMER",
    "Details": [
      {
        "ModelName": "H1",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2006
        ]
      },
      {
        "ModelName": "H2",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "H3",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "H3T",
        "Year": [
          2009,
          2010
        ]
      }
    ]
  },
  {
    "Make": "Honda",
    "Details": [
      {
        "ModelName": "Accord",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Accord Crosstour",
        "Year": [
          2010,
          2011
        ]
      },
      {
        "ModelName": "Accord Hybrid",
        "Year": [
          2014,
          2015,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "CR-V",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "CR-V Hybrid",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "CR-Z",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Civic",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Civic Type R",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Clarity Electric",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "Clarity Fuel Cell",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "Clarity Plug-in Hybrid",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "Crosstour",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Element",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Fit",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "HR-V",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Insight",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2010,
          2011,
          2012,
          2013,
          2014,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Odyssey",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Passport",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Pilot",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Prelude",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Ridgeline",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "S2000",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "del Sol",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      }
    ]
  },
  {
    "Make": "Hyundai",
    "Details": [
      {
        "ModelName": "Accent",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Azera",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Elantra",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Elantra GT",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Entourage",
        "Year": [
          2007,
          2008
        ]
      },
      {
        "ModelName": "Equus",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Excel",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Genesis",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Genesis Coupe",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Ioniq Electric",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Ioniq Hybrid",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Ioniq Plug-in Hybrid",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Kona",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Kona Electric",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "NEXO",
        "Year": [
          2019,
          2020
        ]
      },
      {
        "ModelName": "Palisade",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "Santa Fe",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Santa Fe Sport",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Santa Fe XL",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "Scoupe",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Sonata",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Sonata Hybrid",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Sonata Plug-in Hybrid",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Tiburon",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Tucson",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Tucson Fuel Cell",
        "Year": [
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Veloster",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Venue",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "Veracruz",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "XG300",
        "Year": [
          2001
        ]
      },
      {
        "ModelName": "XG350",
        "Year": [
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Coupe",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Creta (ix25)",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Getz",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Grace",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Grandeur",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "H-1 (Starex)",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "HD 120",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "HD 45",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "HD 65",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "HD 72",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "HD 78",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Matrix",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "e-Mighty (EX5/EX6/EX8/EX9)",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Porter",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Staria",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Terracan",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Trajet",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Verna",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "i10",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "i20",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "i30",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "i40",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      }
    ]
  },
  {
    "Make": "INFINITI",
    "Details": [
      {
        "ModelName": "EX",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "FX",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "G",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "I",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "J",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "JX",
        "Year": [
          2013
        ]
      },
      {
        "ModelName": "M",
        "Year": [
          1992,
          2003,
          2004,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Q",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Q40",
        "Year": [
          2015
        ]
      },
      {
        "ModelName": "Q50",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Q60",
        "Year": [
          2014,
          2015,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Q70",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "QX",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "QX30",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "QX50",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "QX60",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "QX70",
        "Year": [
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "QX80",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Isuzu",
    "Details": [
      {
        "ModelName": "Amigo",
        "Year": [
          1992,
          1993,
          1994,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Ascender",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Axiom",
        "Year": [
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Hombre Regular Cab",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Hombre Spacecab",
        "Year": [
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Impulse",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Oasis",
        "Year": [
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Rodeo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Rodeo Sport",
        "Year": [
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Spacecab",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Stylus",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Trooper",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "VehiCROSS",
        "Year": [
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "i-280 Extended Cab",
        "Year": [
          2006
        ]
      },
      {
        "ModelName": "i-290 Extended Cab",
        "Year": [
          2007,
          2008
        ]
      },
      {
        "ModelName": "i-350 Crew Cab",
        "Year": [
          2006
        ]
      },
      {
        "ModelName": "i-370 Crew Cab",
        "Year": [
          2007,
          2008
        ]
      },
      {
        "ModelName": "i-370 Extended Cab",
        "Year": [
          2007,
          2008
        ]
      }
    ]
  },
  {
    "Make": "Jaguar",
    "Details": [
      {
        "ModelName": "E-PACE",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "F-PACE",
        "Year": [
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "F-TYPE",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "I-PACE",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "S-Type",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "X-Type",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "XE",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "XF",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "XJ",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "XK",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      }
    ]
  },
  {
    "Make": "Jeep",
    "Details": [
      {
        "ModelName": "Cherokee",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Comanche Regular Cab",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Commander",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Compass",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Gladiator",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "Grand Cherokee",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Grand Cherokee L",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Grand Wagoneer",
        "Year": [
          2022
        ]
      },
      {
        "ModelName": "Liberty",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Patriot",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Renegade",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Wrangler",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Wrangler Unlimited",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Kia",
    "Details": [
      {
        "ModelName": "Amanti",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Borrego",
        "Year": [
          2009
        ]
      },
      {
        "ModelName": "Cadenza",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Forte",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Forte Koup",
        "Year": [
          2015,
          2016
        ]
      },
      {
        "ModelName": "Forte5",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "K5",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "K900",
        "Year": [
          2015,
          2016,
          2017,
          2019
        ]
      },
      {
        "ModelName": "Niro",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Niro EV",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "Niro Plug-in Hybrid",
        "Year": [
          2018,
          2019
        ]
      },
      {
        "ModelName": "Optima",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Optima (2006.5)",
        "Year": [
          2006
        ]
      },
      {
        "ModelName": "Optima Hybrid",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Optima Plug-in Hybrid",
        "Year": [
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Rio",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Rondo",
        "Year": [
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Sedona",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Seltos",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Sephia",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Sorento",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Soul",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Soul EV",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Spectra",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Sportage",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Stinger",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Telluride",
        "Year": [
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Ceed",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      }
    ]
  },
  {
    "Make": "Lamborghini",
    "Details": [
      {
        "ModelName": "Aventador",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Gallardo",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Huracan",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Murcielago",
        "Year": [
          2006,
          2007,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Murcielago LP640",
        "Year": [
          2008
        ]
      }
    ]
  },
  {
    "Make": "Land Rover",
    "Details": [
      {
        "ModelName": "Defender 110",
        "Year": [
          1993
        ]
      },
      {
        "ModelName": "Defender 90",
        "Year": [
          1994,
          1995,
          1997
        ]
      },
      {
        "ModelName": "Discovery",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2003,
          2004,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Discovery Series II",
        "Year": [
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Discovery Sport",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Freelander",
        "Year": [
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "LR2",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "LR3",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "LR4",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Range Rover",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Range Rover Evoque",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Range Rover Sport",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Range Rover Velar",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Lexus",
    "Details": [
      {
        "ModelName": "CT",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "ES",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "GS",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "GX",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "HS",
        "Year": [
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "IS",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "IS F",
        "Year": [
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "LC",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "LFA",
        "Year": [
          2012
        ]
      },
      {
        "ModelName": "LS",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "LX",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "NX",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "RC",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "RX",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "SC",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "UX",
        "Year": [
          2019,
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Lincoln",
    "Details": [
      {
        "ModelName": "Aviator",
        "Year": [
          2003,
          2004,
          2005,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Blackwood",
        "Year": [
          2002
        ]
      },
      {
        "ModelName": "Continental",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Corsair",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "LS",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "MKC",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "MKS",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "MKT",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "MKX",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "MKZ",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Mark LT",
        "Year": [
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Mark VII",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Mark VIII",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Nautilus",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Navigator",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Navigator L",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Town Car",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Zephyr",
        "Year": [
          2006
        ]
      }
    ]
  },
  {
    "Make": "Lotus",
    "Details": [
      {
        "ModelName": "Elise",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Evora",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Evora 400",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Exige",
        "Year": [
          2006,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Exige S",
        "Year": [
          2007,
          2008
        ]
      }
    ]
  },
  {
    "Make": "MAZDA",
    "Details": [
      {
        "ModelName": "323",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "626",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "929",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "B-Series Cab Plus",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "B-Series Extended Cab",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "B-Series Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "CX-3",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "CX-30",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "CX-5",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "CX-7",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "CX-9",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "MAZDA2",
        "Year": [
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "MAZDA3",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "MAZDA5",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "MAZDA6",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "MPV",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "MX-3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "MX-5 Miata",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "MX-5 Miata RF",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "MX-6",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Millenia",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Navajo",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Protege",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Protege5",
        "Year": [
          2002,
          2003
        ]
      },
      {
        "ModelName": "RX-7",
        "Year": [
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "RX-8",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Tribute",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2008,
          2009,
          2010,
          2011
        ]
      }
    ]
  },
  {
    "Make": "MINI",
    "Details": [
      {
        "ModelName": "Clubman",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Convertible",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Cooper",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Countryman",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Coupe",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Hardtop",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Hardtop 2 Door",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Hardtop 4 Door",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Paceman",
        "Year": [
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Roadster",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      }
    ]
  },
  {
    "Make": "Maserati",
    "Details": [
      {
        "ModelName": "Coupe",
        "Year": [
          2005,
          2006
        ]
      },
      {
        "ModelName": "Ghibli",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "GranSport",
        "Year": [
          2005,
          2006
        ]
      },
      {
        "ModelName": "GranTurismo",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Levante",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Quattroporte",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      }
    ]
  },
  {
    "Make": "Maybach",
    "Details": [
      {
        "ModelName": "57",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "62",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      }
    ]
  },
  {
    "Make": "McLaren",
    "Details": [
      {
        "ModelName": "570GT",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "570S",
        "Year": [
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "650S",
        "Year": [
          2015,
          2016
        ]
      },
      {
        "ModelName": "675LT",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "720S",
        "Year": [
          2018
        ]
      },
      {
        "ModelName": "MP4-12C",
        "Year": [
          2012,
          2013,
          2014
        ]
      }
    ]
  },
  {
    "Make": "Mercedes-Benz",
    "Details": [
      {
        "ModelName": "190 E",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 CE",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 D",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 E",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 SD",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 SE",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 SL",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "300 TE",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "400 E",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "400 SE",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "400 SEL",
        "Year": [
          1993
        ]
      },
      {
        "ModelName": "500 E",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "500 SEC",
        "Year": [
          1993
        ]
      },
      {
        "ModelName": "500 SEL",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "500 SL",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "600 SEC",
        "Year": [
          1993
        ]
      },
      {
        "ModelName": "600 SEL",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "600 SL",
        "Year": [
          1993
        ]
      },
      {
        "ModelName": "A-Class",
        "Year": [
          2019,
          2021
        ]
      },
      {
        "ModelName": "B-Class",
        "Year": [
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "C-Class",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "CL-Class",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "CLA",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "CLA-Class",
        "Year": [
          2014,
          2015
        ]
      },
      {
        "ModelName": "CLK-Class",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "CLS",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "CLS-Class",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "E-Class",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "G-Class",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "GL-Class",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "GLA",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "GLA-Class",
        "Year": [
          2015
        ]
      },
      {
        "ModelName": "GLB",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "GLC",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "GLC Coupe",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "GLE",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "GLE Coupe",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "GLK-Class",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "GLS",
        "Year": [
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "M-Class",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Mercedes-AMG C-Class",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG CLA",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG CLS",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG E-Class",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG G-Class",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Mercedes-AMG GLA",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG GLC",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG GLC Coupe",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG GLE",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG GLE Coupe",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG GLS",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG GT",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG S-Class",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG SL",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG SLC",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Mercedes-AMG SLK",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "Mercedes-Maybach S 600",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "Mercedes-Maybach S-Class",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Metris Cargo",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Metris Passenger",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Metris WORKER Cargo",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Metris WORKER Passenger",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "R-Class",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "S-Class",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "SL",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "SL-Class",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "SLC",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "SLK",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "SLK-Class",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "SLR McLaren",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "SLS-Class",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Sprinter 2500 Cargo",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Sprinter 2500 Crew",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter 2500 Passenger",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter 3500 Cargo",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter 3500 XD Cargo",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter WORKER Cargo",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Sprinter WORKER Passenger",
        "Year": [
          2017
        ]
      },
      {
        "ModelName": "Mercedes C220",
        "Year": [
          2017
        ]
      },
      {
        "ModelName": "Vito",
        "Year": [
          2024
        ]
      }
    ]
  },
  {
    "Make": "Mercury",
    "Details": [
      {
        "ModelName": "Capri",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Cougar",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Grand Marquis",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Marauder",
        "Year": [
          2003,
          2004
        ]
      },
      {
        "ModelName": "Mariner",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Milan",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Montego",
        "Year": [
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Monterey",
        "Year": [
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Mountaineer",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Mystique",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Sable",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Topaz",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Tracer",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Villager",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      }
    ]
  },
  {
    "Make": "Mitsubishi",
    "Details": [
      {
        "ModelName": "3000GT",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Diamante",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Eclipse",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Eclipse Cross",
        "Year": [
          2018,
          2019,
          2020,
          2022
        ]
      },
      {
        "ModelName": "Endeavor",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Expo",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Galant",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Lancer",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Lancer Evolution",
        "Year": [
          2015
        ]
      },
      {
        "ModelName": "Mighty Max Macro Cab",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Mighty Max Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Mirage",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2014,
          2015,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Mirage G4",
        "Year": [
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Montero",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Montero Sport",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Outlander",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Outlander PHEV",
        "Year": [
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Outlander Sport",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Precis",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Raider Double Cab",
        "Year": [
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Raider Extended Cab",
        "Year": [
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "i-MiEV",
        "Year": [
          2012,
          2014,
          2016,
          2017
        ]
      },
      {
        "ModelName": "ASX",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Carisma",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Celeste",
        "Year": [
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982
        ]
      },
      {
        "ModelName": "Colt",
        "Year": [1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Cordia",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      },
      {
        "ModelName": "Debonair",
        "Year": [
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Delica",
        "Year": [
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Delica D:2",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Delica D:3",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Delica D:5",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Delica Mini",
        "Year": [
          2023
        ]
      },
      {
        "ModelName": "Dignity",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "eK",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Freeca",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "FTO",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Galant FTO",
        "Year": [
          1971,
          1972,
          1973,
          1974,
          1975
        ]
      },
      {
        "ModelName": "Galant Lambda",
        "Year": [
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984
        ]
      },
      {
        "ModelName": "Grandis",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "GTO",
        "Year": [
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "i",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "L200",
        "Year": [
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "L300",
        "Year": [
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Minica",
        "Year": [
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "Minicab",
        "Year": [
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Pajero",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Pajero Mini",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Pajero Pinin",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Pajero Sport",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Proudia",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Sapporo",
        "Year": [
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      },
      {
        "ModelName": "Sigma",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Space Gear",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Space Runner",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Space Star",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Space Wagon",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Starion",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      },
      {
        "ModelName": "Xforce",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Xpander",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Tredia",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      }
    ]
  },
  {
    "Make": "Nissan",
    "Details": [
      {
        "ModelName": "200SX",
        "Year": [
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "240SX",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "300ZX",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "350Z",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "370Z",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Altima",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Armada",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Frontier Crew Cab",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Frontier King Cab",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Frontier Regular Cab",
        "Year": [
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "GT-R",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "JUKE",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Kicks",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "King Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "LEAF",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Maxima",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Murano",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "NV1500 Cargo",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "NV200",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "NV200 Taxi",
        "Year": [
          2015,
          2016,
          2019
        ]
      },
      {
        "ModelName": "NV2500 HD Cargo",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "NV3500 HD Cargo",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "NV3500 HD Passenger",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "NX",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Pathfinder",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2022
        ]
      },
      {
        "ModelName": "Pathfinder Armada",
        "Year": [
          2004
        ]
      },
      {
        "ModelName": "Quest",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Rogue",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Rogue Select",
        "Year": [
          2014,
          2015
        ]
      },
      {
        "ModelName": "Rogue Sport",
        "Year": [
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Sentra",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Stanza",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "TITAN Single Cab",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "TITAN XD Crew Cab",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "TITAN XD King Cab",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "TITAN XD Single Cab",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Titan Crew Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Titan King Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Versa",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Versa Note",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Xterra",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "cube",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Tiida",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Xtrail",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      }
    ]
  },
  {
    "Make": "Oldsmobile",
    "Details": [
      {
        "ModelName": "88",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "98",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996
        ]
      },
      {
        "ModelName": "Achieva",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Alero",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Aurora",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Bravada",
        "Year": [
          1992,
          1993,
          1994,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Ciera",
        "Year": [
          1996
        ]
      },
      {
        "ModelName": "Custom Cruiser",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Cutlass",
        "Year": [
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Cutlass Ciera",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Cutlass Cruiser",
        "Year": [
          1993,
          1994
        ]
      },
      {
        "ModelName": "Cutlass Supreme",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Intrigue",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "LSS",
        "Year": [
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Regency",
        "Year": [
          1997,
          1998
        ]
      },
      {
        "ModelName": "Silhouette",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Toronado",
        "Year": [
          1992
        ]
      }
    ]
  },
  {
    "Make": "Opel",
    "Details": [
      {
        "ModelName": "Antara",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Astra",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Combo",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Corsa",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Crossland X",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Frontera",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Grandland X",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Insignia",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Kadett",
        "Year": [
          1937,
          1938,
          1939,
          1940,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "Meriva",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Mokka",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Omega",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Signum",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Sintra",
        "Year": [
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Tigra",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Vectra",
        "Year": [
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Vita",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Vivaro",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Zafira",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      }
    ]
  },
  {
    "Make": "Panoz",
    "Details": [
      {
        "ModelName": "Esperante",
        "Year": [
          2005,
          2006
        ]
      }
    ]
  },
  {
    "Make": "Plymouth",
    "Details": [
      {
        "ModelName": "Acclaim",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Breeze",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Colt",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Colt Vista",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Grand Voyager",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Laser",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Neon",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Prowler",
        "Year": [
          1997,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Sundance",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Voyager",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      }
    ]
  },
  {
    "Make": "Polestar",
    "Details": [
      {
        "ModelName": "2",
        "Year": [
          2021
        ]
      }
    ]
  },
  {
    "Make": "Pontiac",
    "Details": [
      {
        "ModelName": "Aztek",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Bonneville",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Firebird",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "G3",
        "Year": [
          2009,
          2010
        ]
      },
      {
        "ModelName": "G5",
        "Year": [
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "G6",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "G6 (2009.5)",
        "Year": [
          2009
        ]
      },
      {
        "ModelName": "G8",
        "Year": [
          2008,
          2009
        ]
      },
      {
        "ModelName": "GTO",
        "Year": [
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Grand Am",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Grand Prix",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "LeMans",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Montana",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Montana SV6",
        "Year": [
          2005,
          2006
        ]
      },
      {
        "ModelName": "Solstice",
        "Year": [
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Sunbird",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Sunfire",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Torrent",
        "Year": [
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Trans Sport",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Vibe",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      }
    ]
  },
  {
    "Make": "Porsche",
    "Details": [
      {
        "ModelName": "718 Boxster",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "718 Cayman",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "718 Spyder",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "911",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "928",
        "Year": [
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "968",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Boxster",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Carrera GT",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "Cayenne",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Cayenne Coupe",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "Cayman",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Macan",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Panamera",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Taycan",
        "Year": [
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Ram",
    "Details": [
      {
        "ModelName": "1500 Classic Crew Cab",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "1500 Classic Quad Cab",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "1500 Classic Regular Cab",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "1500 Crew Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "1500 Quad Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "1500 Regular Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "2500 Crew Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "2500 Mega Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "2500 Regular Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "3500 Crew Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "3500 Mega Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "3500 Regular Cab",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "C/V",
        "Year": [
          2012
        ]
      },
      {
        "ModelName": "C/V Tradesman",
        "Year": [
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Dakota Crew Cab",
        "Year": [
          2011
        ]
      },
      {
        "ModelName": "Dakota Extended Cab",
        "Year": [
          2011
        ]
      },
      {
        "ModelName": "ProMaster 1500 Cargo",
        "Year": [
          2014
        ]
      },
      {
        "ModelName": "ProMaster 2500 Cargo",
        "Year": [
          2014
        ]
      },
      {
        "ModelName": "ProMaster 3500 Cargo",
        "Year": [
          2014
        ]
      },
      {
        "ModelName": "ProMaster Cargo Van",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "ProMaster City",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "ProMaster Window Van",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Rivian",
    "Details": [
      {
        "ModelName": "R1S",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "R1T",
        "Year": [
          2021
        ]
      }
    ]
  },
  {
    "Make": "Rolls-Royce",
    "Details": [
      {
        "ModelName": "Dawn",
        "Year": [
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Ghost",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "Phantom",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2018
        ]
      },
      {
        "ModelName": "Wraith",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      }
    ]
  },
  {
    "Make": "SRT",
    "Details": [
      {
        "ModelName": "Viper",
        "Year": [
          2013,
          2014
        ]
      }
    ]
  },
  {
    "Make": "Saab",
    "Details": [
      {
        "ModelName": "3-Sep",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "5-Sep",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "9-2X",
        "Year": [
          2005,
          2006
        ]
      },
      {
        "ModelName": "9-4X",
        "Year": [
          2011
        ]
      },
      {
        "ModelName": "9-7X",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "900",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "9000",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      }
    ]
  },
  {
    "Make": "Saturn",
    "Details": [
      {
        "ModelName": "Astra",
        "Year": [
          2008
        ]
      },
      {
        "ModelName": "Aura",
        "Year": [
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Ion",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "L-Series",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Outlook",
        "Year": [
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Relay",
        "Year": [
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "S-Series",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "SKY",
        "Year": [
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "VUE",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      }
    ]
  },
  {
    "Make": "Scion",
    "Details": [
      {
        "ModelName": "FR-S",
        "Year": [
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "iA",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "iM",
        "Year": [
          2016
        ]
      },
      {
        "ModelName": "iQ",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "tC",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "xA",
        "Year": [
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "xB",
        "Year": [
          2004,
          2005,
          2006,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "xD",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      }
    ]
  },
  {
    "Make": "Subaru",
    "Details": [
      {
        "ModelName": "Ascent",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "B9 Tribeca",
        "Year": [
          2006,
          2007
        ]
      },
      {
        "ModelName": "BRZ",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2022
        ]
      },
      {
        "ModelName": "Baja",
        "Year": [
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Crosstrek",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Forester",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Impreza",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Justy",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Legacy",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Loyale",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Outback",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "SVX",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Tribeca",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "WRX",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "XV Crosstrek",
        "Year": [
          2013,
          2014,
          2015
        ]
      }
    ]
  },
  {
    "Make": "Suzuki",
    "Details": [
      {
        "ModelName": "Aerio",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "Equator Crew Cab",
        "Year": [
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Equator Extended Cab",
        "Year": [
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "Esteem",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Forenza",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Grand Vitara",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Kizashi",
        "Year": [
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Reno",
        "Year": [
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "SX4",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Samurai",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Sidekick",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Swift",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Verona",
        "Year": [
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Vitara",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "X-90",
        "Year": [
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "XL-7",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "XL7",
        "Year": [
          2007,
          2008,
          2009
        ]
      }
    ]
  },
  {
    "Make": "Tesla",
    "Details": [
      {
        "ModelName": "Model 3",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Model S",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Model X",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Model Y",
        "Year": [
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Cybertruck",
        "Year": [
          2024
        ]
      }
    ]
  },
  {
    "Make": "Toyota",
    "Details": [
      {
        "ModelName": "4Runner",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "86",
        "Year": [
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Avalon",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Avalon Hybrid",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "C-HR",
        "Year": [
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Camry",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Camry Hybrid",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Celica",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Corolla",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Corolla Hatchback",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Corolla Hybrid",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "Corolla iM",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Cressida",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "Echo",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "FJ Cruiser",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "GR Supra",
        "Year": [
          2020,
          2021
        ]
      },
      {
        "ModelName": "Highlander",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Highlander Hybrid",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Land Cruiser",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "MR2",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Matrix",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Mirai",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Paseo",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Previa",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Prius",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Prius Plug-in Hybrid",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Prius Prime",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Prius c",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Prius v",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "RAV4",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "RAV4 Hybrid",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "RAV4 Prime",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Regular Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Sequoia",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Sienna",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Solara",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "Supra",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "T100 Regular Cab",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "T100 Xtracab",
        "Year": [
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Tacoma Access Cab",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Tacoma Double Cab",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Tacoma Regular Cab",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Tacoma Xtracab",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Tercel",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Tundra Access Cab",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Tundra CrewMax",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Tundra Double Cab",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Tundra Regular Cab",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Venza",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2021
        ]
      },
      {
        "ModelName": "Xtra Cab",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Yaris",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "Yaris Hatchback",
        "Year": [
          2020
        ]
      },
      {
        "ModelName": "Yaris iA",
        "Year": [
          2017,
          2018
        ]
      }
    ]
  },
  {
    "Make": "Volkswagen",
    "Details": [
      {
        "ModelName": "Arteon",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "Atlas",
        "Year": [
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Atlas Cross Sport",
        "Year": [
          2021
        ]
      },
      {
        "ModelName": "Beetle",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "CC",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Cabrio",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Cabrio (New)",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "Cabriolet",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "Corrado",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Eos",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Eurovan",
        "Year": [
          1993,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Fox",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "GLI",
        "Year": [
          2008,
          2009
        ]
      },
      {
        "ModelName": "GTI",
        "Year": [
          1992,
          1995,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "GTI (New)",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "Golf",
        "Year": [
          1992,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "Golf (New)",
        "Year": [
          1999
        ]
      },
      {
        "ModelName": "Golf Alltrack",
        "Year": [
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Golf GTI",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Golf III",
        "Year": [
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Golf R",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Golf SportWagen",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Jetta",
        "Year": [
          1992,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Jetta (New)",
        "Year": [
          1999,
          2005
        ]
      },
      {
        "ModelName": "Jetta GLI",
        "Year": [
          2019
        ]
      },
      {
        "ModelName": "Jetta III",
        "Year": [
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Jetta SportWagen",
        "Year": [
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "New Beetle",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "Passat",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Passat (New)",
        "Year": [
          2001
        ]
      },
      {
        "ModelName": "Phaeton",
        "Year": [
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "R32",
        "Year": [
          2004,
          2008
        ]
      },
      {
        "ModelName": "Rabbit",
        "Year": [
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Routan",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Tiguan",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Tiguan Limited",
        "Year": [
          2017,
          2018
        ]
      },
      {
        "ModelName": "Touareg",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "Touareg 2",
        "Year": [
          2008,
          2009
        ]
      },
      {
        "ModelName": "e-Golf",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      }
    ]
  },
  {
    "Make": "Volvo",
    "Details": [
      {
        "ModelName": "240",
        "Year": [
          1992,
          1993
        ]
      },
      {
        "ModelName": "740",
        "Year": [
          1992
        ]
      },
      {
        "ModelName": "850",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "940",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "960",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "C30",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "C70",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "S40",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "S40 (New)",
        "Year": [
          2004
        ]
      },
      {
        "ModelName": "S60",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "S70",
        "Year": [
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "S80",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "S90",
        "Year": [
          1997,
          1998,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "V40",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "V50",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "V60",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "V70",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "V90",
        "Year": [
          1997,
          1998,
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "XC40",
        "Year": [
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "XC60",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "XC70",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "XC90",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      }
    ]
  },
  {
    "Make": "smart",
    "Details": [
      {
        "ModelName": "fortwo",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "fortwo cabrio",
        "Year": [
          2017
        ]
      },
      {
        "ModelName": "fortwo electric drive",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "fortwo electric drive cabrio",
        "Year": [
          2017,
          2018
        ]
      }
    ]
  },
  {
    "Make":"LADA (VAZ)",
    "Details":[
      {
        "ModelName":"2101",
        "Year":[
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989
        ]
      },
      {
        "ModelName":"21011",
          "Year":[
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981
        ]
      },
      {
        "ModelName":"21013",
        "Year":[
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988
        ]
      },
      {
        "ModelName":"2102",
        "Year":[
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986
        ]
      },
      {
        "ModelName":"2103",
        "Year":[
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984
        ]
      },
      {
        "ModelName":"2104",
        "Year":[
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName":"2105",
        "Year":[
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName":"2106",
        "Year":[
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName":"2107",
        "Year":[
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName":"2110 (110)",
        "Year":[
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName":"2111 (111)",
        "Year":[
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName":"2112 (112)",
        "Year":[
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName":"Granta",
        "Year":[
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Granta Cross",
        "Year":[
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Kalina",
        "Year":[
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName":"Largus",
        "Year":[
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Largus Cross",
        "Year":[
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Nadejda",
        "Year":[
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName":"Niva",
        "Year":[
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName":"Niva Legend",
        "Year":[
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Niva Bronto",
        "Year":[
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Niva Travel",
        "Year":[
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Oka",
        "Year":[
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName":"Priora",
        "Year":[
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName":"Samara (2108)",
        "Year":[
          1984,
          1985,
          1996,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName":"Samara (2109)",
        "Year":[
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName":"Samara (21099)",
        "Year":[
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName":"Samara2 (2113)",
        "Year":[
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName":"Samara2 (2114)",
        "Year":[
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName":"Samara2 (2115)",
        "Year":[
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName":"Vesta",
        "Year":[
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Vesta Cross",
        "Year":[
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Vesta SW",
        "Year":[
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"Vesta SW Cross",
        "Year":[
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName":"XRAY",
        "Year":[
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName":"XRAY Cross",
        "Year":[
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName":"X-Cross 5",
        "Year":[
          2023
        ]
      }
    ]
  },    
  {
    "Make": "Abarth",
    "Details": [
      {
        "ModelName": "500",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "595",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "695",
        "Year": [
          2015,
          2016,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Pulse",
        "Year": [
          2017,
          2018,
          2019,
          2021
        ]
      },
      {
        "ModelName": "1000",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "103 GT",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "124 Spider",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "205",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "2200",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "750",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "850",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Nuova 500",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Punto",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Ritmo",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Scorpione",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Simca 1300",
        "Year": [
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "Simca 2000",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "AITO",
    "Details": [
      {
        "ModelName": "M5",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "M7",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Avatr",
    "Details": [
      {
        "ModelName": "11",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "12",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Baic",
    "Details": [
      {
        "ModelName": "BJ20",
        "Year": [
          2016,
          2017,
          1994,
          1995
        ]
      },
      {
        "ModelName": "BJ40",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "BJ80",
        "Year": [
          2015,
          2016,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Concept",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "E series",
        "Year": [
          2012,
          2013,
          2014
        ]
      }
    ]
  },
  {
    "Make": "Bestune",
    "Details": [
      {
        "ModelName": "B70S",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "NAT",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "T55",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "T77",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "T99",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "BYD",
    "Details": [
      {
        "ModelName": "Han",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Seal",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Song",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Song Plus",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tang",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Yuan",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Yuan Plus",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "e2",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Changan",
    "Details": [
      {
        "ModelName": "A800",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Alsvin",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Alsvin V3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Alsvin V5",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Alsvin V7",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Benni",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Benni Love",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Benni Mini",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS15",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS35",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS55",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS75",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS75 Plus",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS85",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CS95",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CX20",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CX30",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "CX70",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Eado",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Eulove",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Honor",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Lingxuan",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Oushang",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Raeton",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Raeton CC",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Star",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Star 2",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Star 3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Star 7",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Star 9",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Uni-T",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "X70A",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Z-Shine",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Chery",
    "Details": [
      {
        "ModelName": "A1",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "A11",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "A13",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "A15",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "A3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "A5",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Amulet",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Arrizo 3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Arrizo 5",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Arrizo 7",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Arrizo 8",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Arrizo M7",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Concept",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "E3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "E5",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "E8",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Eastar",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "eQ1",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Exeed TX",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "QQ",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "QQ3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "QQ6",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tiggo",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tiggo 3",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tiggo 5",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tiggo 7",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tiggo 8",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Tiggo 9",
        "Year": [
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "V5",
        "Year": [
          2018,
          2019,
          2021
        ]
      }
    ]
  },
  {
    "Make": "Citroen",
    "Details": [
      {
        "ModelName": "2CV",
        "Year": [
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      },
      {
        "ModelName": "Acadiane",
        "Year": [
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987
        ]
      },
      {
        "ModelName": "Ami",
        "Year": [
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978
        ]
      },
      {
        "ModelName": "Ami Micro",
        "Year": [
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "AX",
        "Year": [
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "Axel",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      },
      {
        "ModelName": "Berlingo",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "BX",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "C-Crossover",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "C-Triomphe",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C-Zero",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C1",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C15",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "C2",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "C25",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "C3",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C3 Aircross",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C3 Picasso",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "C3-XR",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C4",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C4 Aircross",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C4 Cactus",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C4 L",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C4 Picasso",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C4 Shijia",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C5",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C5 Aircross",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C6",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "C8",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "CX",
        "Year": [
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "DS",
        "Year": [
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975
        ]
      },
      {
        "ModelName": "DS3",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "DS4",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "DS5",
        "Year": [
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Dyane",
        "Year": [
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983
        ]
      },
      {
        "ModelName": "e-Mehari",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Elysee",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Evasion",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "FAF",
        "Year": [
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985
        ]
      },
      {
        "ModelName": "Fukang",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "GS",
        "Year": [
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986
        ]
      },
      {
        "ModelName": "H VAN",
        "Year": [
          1947,
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981
        ]
      },
      {
        "ModelName": "Jumper",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Jumpy",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "LN",
        "Year": [
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986
        ]
      },
      {
        "ModelName": "M35",
        "Year": [
          1970,
          1971
        ]
      },
      {
        "ModelName": "Mehari",
        "Year": [
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987
        ]
      },
      {
        "ModelName": "Nemo",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Saxo",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "SM",
        "Year": [
          1970,
          1971,
          1972,
          1973,
          1974,
          1975
        ]
      },
      {
        "ModelName": "Visa",
        "Year": [
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988
        ]
      },
      {
        "ModelName": "Xantia",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "XM",
        "Year": [
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Xsara",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Xsara Picasso",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "ZX",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      }
    ]
  },
  {
    "Make": "Peugeot",
    "Details": [
      {
        "ModelName": "1007",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "104",
        "Year": [
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988
        ]
      },
      {
        "ModelName": "106",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "107",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "108",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "2008",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "202",
        "Year": [
          1938,
          1939,
          1940,
          1941,
          1942,
          1943,
          1944,
          1945,
          1946,
          1947,
          1948,
          1949
        ]
      },
      {
        "ModelName": "203",
        "Year": [
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960
        ]
      },
      {
        "ModelName": "204",
        "Year": [
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976
        ]
      },
      {
        "ModelName": "205",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998
        ]
      },
      {
        "ModelName": "206",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "207",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "208",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "3008",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "301",
        "Year": [
          1932,
          1933,
          1934,
          1935,
          1936,
          1937,
          1938,
          1939,
          1940,
          1941,
          1942,
          1943,
          1944,
          1945,
          1946,
          1947,
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "304",
        "Year": [
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980
        ]
      },
      {
        "ModelName": "305",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989
        ]
      },
      {
        "ModelName": "306",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "307",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "308",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "309",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "4007",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "4008",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      },
      {
        "ModelName": "402",
        "Year": [
          1935,
          1936,
          1937,
          1938,
          1939,
          1940,
          1941,
          1942
        ]
      },
      {
        "ModelName": "403",
        "Year": [
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967
        ]
      },
      {
        "ModelName": "404",
        "Year": [
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981
        ]
      },
      {
        "ModelName": "405",
        "Year": [
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "406",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "407",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012
        ]
      },
      {
        "ModelName": "408",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "5008",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "504",
        "Year": [
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "505",
        "Year": [
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992
        ]
      },
      {
        "ModelName": "508",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "604",
        "Year": [
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986
        ]
      },
      {
        "ModelName": "605",
        "Year": [
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "607",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "806",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "807",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "Bipper",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Boxer",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Expert",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Hoggar",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014
        ]
      },
      {
        "ModelName": "iOn",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "J5",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "J9",
        "Year": [            
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "Landtrek",
        "Year": [
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Partner",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Pick Up",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "RCZ",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Rifter",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Traveller",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      }
    ]
  },
  {
    "Make": "Dacia",
    "Details": [
      {
        "ModelName": "1100",
        "Year": [
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "1300",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "500 Lastun",
        "Year": [
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "Dokker",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Duster",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Jogger",
        "Year": [
          2022,
          2023
        ]
      },
      {
        "ModelName": "Lodgy",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Logan",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Nova",
        "Year": [
          1995,
          1996,
          1997,
          1998,
          1999,
          2000
        ]
      },
      {
        "ModelName": "Pick-Up",
        "Year": [
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Sandero",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Solenza",
        "Year": [
          2003,
          2004,
          2005
        ]
      },
      {
        "ModelName": "Spring",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "SupeRNova",
        "Year": [
          2000,
          2001,
          2002,
          2003
        ]
      }
    ]
  },
  {
    "Make": "GAZ",
    "Details": [
      {
        "ModelName": "12",
        "Year": [
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960
        ]
      },
      {
        "ModelName": "13",
        "Year": [
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981
        ]
      },
      {
        "ModelName": "14",
        "Year": [
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989
        ]
      },
      {
        "ModelName": "21",
        "Year": [
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970
        ]
      },
      {
        "ModelName": "24",
        "Year": [
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993
        ]
      },
      {
        "ModelName": "3102",
        "Year": [
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "31105",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "3111",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "51",
        "Year": [
          1946,
          1947,
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975
        ]
      },
      {
        "ModelName": "A",
        "Year": [
          1932,
          1933,
          1934,
          1935,
          1936
        ]
      },
      {
        "ModelName": "AA",
        "Year": [
          1932,
          1933,
          1934,
          1935,
          1936,
          1937,
          1938
        ]
      },
      {
        "ModelName": "M-1",
        "Year": [
          1936,
          1937,
          1938,
          1939,
          1940,
          1941,
          1942,
          1943,
          1944,
          1945,
          1946,
          1947,
          1948
        ]
      },
      {
        "ModelName": "M-20",
        "Year": [
          1946,
          1947,
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958
        ]
      },
      {
        "ModelName": "M-72",
        "Year": [
          1955,
          1956,
          1957,
          1958
        ]
      }
    ]
  },
  {
    "Make": "Geely",
    "Details": [
      {
        "ModelName": "Binyue",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "BL",
        "Year": [
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Borui GE",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Boyue",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "CD",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "CK",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Emgrand EC7",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Emgrand SL",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "FC",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "GC5",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "GC7",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "GC9",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "GX2",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "GX7",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "GX9",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "HQ",
        "Year": [
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Jiaji",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "LC",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "MK",
        "Year": [
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "MR",
        "Year": [
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "Mybo",
        "Year": [
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "PU",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "SX11",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Vision X6",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      }
    ]
  },
  {
    "Make": "Haval",
    "Details": [
      {
        "ModelName": "Big Dog",
        "Year": [
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Chitu",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Cool Dog",
        "Year": [
          2022,
          2023
        ]
      },
      {
        "ModelName": "F5",
        "Year": [
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "F7",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "H1",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "H2",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "H2s",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "H3",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "H4",
        "Year": [
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "H5",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "H6",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "H6 Coupe",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "H7",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "H8",
        "Year": [
          2015,
          2016,
          2017,
          2018
        ]
      },
      {
        "ModelName": "H9",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Jolion",
        "Year": [
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "M4",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "M6",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Shenshou",
        "Year": [
          2022,
          2023
        ]
      }
    ]
  },
  {
    "Make": "Ravon",
    "Details": [
      {
        "ModelName": "Gentra",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Nexia R3",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "R2",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "R4",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      }
    ]
  },
  {
    "Make": "Renault",
    "Details": [
      {
        "ModelName": "10",
        "Year": [
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972
        ]
      },
      {
        "ModelName": "12",
        "Year": [
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980
        ]
      },
      {
        "ModelName": "18",
        "Year": [
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986
        ]
      },
      {
        "ModelName": "19",
        "Year": [
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "21",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994
        ]
      },
      {
        "ModelName": "25",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992
        ]
      },
      {
        "ModelName": "5",
        "Year": [
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "9",
        "Year": [
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989
        ]
      },
      {
        "ModelName": "Alaskan",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Arkana",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Austral",
        "Year": [
          2023
        ]
      },
      {
        "ModelName": "Captur",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Clio",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Dokker",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Duster",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Espace",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Express",
        "Year": [
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002
        ]
      },
      {
        "ModelName": "Fluence",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Fuego",
        "Year": [
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989
        ]
      },
      {
        "ModelName": "Grand Scenic",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "K-ZE",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Kadjar",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Kangoo",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Kaptur",
        "Year": [
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Kardian",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Kiger",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Koleos",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Kwid",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Laguna",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Latitude",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Lodgy",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Logan",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Master",
        "Year": [
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Master Conversion Chassi Platform",
        "Year": [
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Megane",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Megane Scenic",
        "Year": [
          1996,
          1997,
          1998,
          1999
        ]
      },
      {
        "ModelName": "Megane E-TECH",
        "Year": [
          2022,
          2023
        ]
      },
      {
        "ModelName": "Midlum",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Modus",
        "Year": [
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "R.S. 01",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Rafale",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Safrane",
        "Year": [
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Sandero",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Scenic",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Scenin E-TECH",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Symbol",
        "Year": [
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Talisman",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Tondar",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Trafic",
        "Year": [
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Triber",
        "Year": [
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Twingo",
        "Year": [
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Twizy",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Vel Satis",
        "Year": [
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010
        ]
      },
      {
        "ModelName": "ZOE",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      }
    ]
  },
  {
    "Make": "SEAT",
    "Details": [
      {
        "ModelName": "Alhambra",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ]
      },
      {
        "ModelName": "Altea",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Arona",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Arosa",
        "Year": [
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004
        ]
      },
      {
        "ModelName": "Ateca",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Cordoba",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Exeo",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013
        ]
      },
      {
        "ModelName": "Ibiza",
        "Year": [
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Inca",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003
        ]
      },
      {
        "ModelName": "Malaga",
        "Year": [
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "Mii",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021
        ]
      },
      {
        "ModelName": "Panda",
        "Year": [
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987
        ]
      },
      {
        "ModelName": "Leon",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Tarraco",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Toledo",
        "Year": [
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      }
    ]
  },
  {
    "Make": "Skoda",
    "Details": [
      {
        "ModelName": "Citigo",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Enyaq iV",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Fabia",
        "Year": [
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Favorit",
        "Year": [
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995
        ]
      },
      {
        "ModelName": "Felicia",
        "Year": [
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001
        ]
      },
      {
        "ModelName": "Kamiq",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Karoq",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Kodiaq",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Oktavia",
        "Year": [
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Rapid",
        "Year": [
          1935,
          1936,
          1937,
          1938,
          1939,
          1940,
          1941,
          1942,
          1943,
          1944,
          1945,
          1946,
          1947,
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "Roomster",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015
        ]
      },
      {
        "ModelName": "Scala",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Superb",
        "Year": [
          1934,
          1935,
          1936,
          1937,
          1938,
          1939,
          1940,
          1941,
          1942,
          1943,
          1944,
          1945,
          1946,
          1947,
          1948,
          1949,
          1950,
          1951,
          1952,
          1953,
          1954,
          1955,
          1956,
          1957,
          1958,
          1959,
          1960,
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "Yeti",
        "Year": [
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017
        ]
      }
    ]
  },
  {
    "Make": "VGV",
    "Details": [
      {
        "ModelName": "U70 PRO",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "U75 PLUS",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      }
    ]
  },
  {
    "Make": "ZEEKR",
    "Details": [
      {
        "ModelName": "001",
        "Year": [
          2021,
          2022,
          2023
        ]
      },
      {
        "ModelName": "007",
        "Year": [
          2023
        ]
      },
      {
        "ModelName": "009",
        "Year": [
          2023
        ]
      },
      {
        "ModelName": "X",
        "Year": [
          2023
        ]
      }
    ]
  },
  {
    "Make": "MG",
    "Details": [
      {
        "ModelName": "3",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "360",
        "Year": [
          2016,
          2017,
          2018,
          2019
        ]
      },
      {
        "ModelName": "4",
        "Year": [
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "5",
        "Year": [
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "5 EV",
        "Year": [
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "6",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "7",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Cyberser",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Extender",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "F",
        "Year": [
          1996,
          1997,
          1998,
          1999,
          2000,
          2001,
          2002,
          2003,
          2004,
          2005,
          2006,
          2007,
          2008,
          2009,
          2010,
          2011
        ]
      },
      {
        "ModelName": "GS",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020
        ]
      },
      {
        "ModelName": "GT",
        "Year": [
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Hector",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "HS",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Maestro",
        "Year": [
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "Metro",
        "Year": [
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990
        ]
      },
      {
        "ModelName": "Marvel R",
        "Year": [
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "MGB",
        "Year": [
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980,
          1981,
          1982,
          1983,
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991,
          1992,
          1993,
          1994,
          1995,
          1996,
          1997
        ]
      },
      {
        "ModelName": "Midget",
        "Year": [
          1961,
          1962,
          1963,
          1964,
          1965,
          1966,
          1967,
          1968,
          1969,
          1970,
          1971,
          1972,
          1973,
          1974,
          1975,
          1976,
          1977,
          1978,
          1979,
          1980
        ]
      },
      {
        "ModelName": "Montego",
        "Year": [
          1984,
          1985,
          1986,
          1987,
          1988,
          1989,
          1990,
          1991
        ]
      },
      {
        "ModelName": "Mulan",
        "Year": [
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "One",
        "Year": [
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Pilot",
        "Year": [
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "RX5",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "RX8",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "V80",
        "Year": [
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "XPower SV",
        "Year": [
          2003,
          2004,
          2005,
          2006,
          2007,
          2008
        ]
      },
      {
        "ModelName": "ZR",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "ZS",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005,
          2006
        ]
      },
      {
        "ModelName": "ZS SUV",
        "Year": [
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "ZT",
        "Year": [
          2001,
          2002,
          2003,
          2004,
          2005
        ]
      }
    ]
  },
  {
    "Make": "Lifan",
    "Details": [
      {
        "ModelName": "200",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "210",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "220",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "300",
        "Year": [
          2004,
          2005,
          2006,
          2007
        ]
      },
      {
        "ModelName": "320",
        "Year": [
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "500",
        "Year": [
          2004,
          2005
        ]
      },
      {
        "ModelName": "520",
        "Year": [
          2006,
          2007,
          2008,
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "620",
        "Year": [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "720",
        "Year": [
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "820",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Cruiser",
        "Year": [
          2004,
          2005,
          2006,
          2007,
          2008,
          2009
        ]
      },
      {
        "ModelName": "Foison",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016
        ]
      },
      {
        "ModelName": "Letu",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "Myway",
        "Year": [
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "X50",
        "Year": [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "X60",
        "Year": [
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "X70",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      }
    ]
  },
  {
    "Make": "Khazar (AzSamand)",
    "Details": [
      {
        "ModelName": "CC",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "LD",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "LX",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      },
      {
        "ModelName": "SD",
        "Year": [
          2018,
          2019,
          2020,
          2021,
          2022,
          2023,
          2024
        ]
      }
    ]
  },
  {
    "Make": "Great Wall",
    "Details": [
      {
        "ModelName": "Coolbear",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Cowry",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Deer",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Florid",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Hover",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "M2",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "M4",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Pegasus",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Peri",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Safe",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Sailor",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Tank 500",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Voleex C10",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Voleex C30",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Voleex C50",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Wingle 3",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Wingle 5",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Wingle 6",
        "Year": [
          2024
        ]
      }
    ]
  },
  {
    "Make": "Iran Khodro",
    "Details": [
      {
        "ModelName": "Runna",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Samand",
        "Year": [
          2024
        ]
      },
      {
        "ModelName": "Soren",
        "Year": [
          2024
        ]
      }
    ]
  }
]
export const carsName = data.map((item, index) => {
  return {id: index, title: item.Make};
});
export const getCarModels = a => {
  return data[a?.id].Details.map((item, index) => {
    return {id: index, title: item.ModelName};
  });
};

export const getCarYears = (model, name) => {
  return data[name?.id].Details[model?.id].Year.map((item, index) => {
    return {id: index, title: item.toString()};
  });
};

