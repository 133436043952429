import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../assets/styles/banner.css";
import BannerDesktop from "./BannerDesktop";
import BannerMobile from "./BannerMobile";

function Banner({ btnRef }) {
  const goToCategories = () => {
    window.scrollTo({ top: btnRef.current.offsetTop, behavior: "smooth" });
  };

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 || window.screen.width < 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="banner">
      <Navbar />
      {!isMobile ? <BannerDesktop /> : <BannerMobile />}
    </div>
  );
}

export default Banner;


{
  /* <div className="banner-content">
  <div className="banner-left">
    <div className="banner-texts">
      <h1 className="banner-heading">
        Yolçu
        <div>
          <span>bid</span>
        </div>
        -də gərək!
      </h1>
      <p className="banner-small-text">
        Sizin rahatlığınızı və büdcənizi düşünən bir tətbiq
      </p>
      <div className="social-links">
        <img className="stores-logo" src={playStore} alt="play store" />
        <img className="stores-logo" src={appStore} alt="app store" />
      </div>
      <div className="scroll-down" onClick={goToCategories}>
        <img src={mouse} alt="scroll-bar-icon" className="scroll-down-mouse" />
      </div>
    </div>
  </div>
  <div className="banner-right">
    <div className="banner-right-image"></div>
  </div>
</div>; */
}
