import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import "../assets/styles/faq.css";
import "@fortawesome/free-solid-svg-icons";

function Faq() {
  const [selected, setSelected] = useState(null);
  const [faq, setFaq] = useState([]);
  const [limit, setLimit] = useState(4);
  const [showBtn, setShowBtn] = useState(true);
  const [elems, setElems] = useState([]);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          window.location.origin + "/faq.json"
        );
        setFaq(response.data);
        func(response.data, false);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log(`Error: ${err.message}`);
        }
      }
    };
    getData();
  }, []);
  useEffect(() => {
    func(faq, false);
  }, [selected]);

  const func = (faq, bool) => {
    const a = faq
      .slice(0, bool === undefined ? limit + 2 : limit)
      .map((item, i) => {
        return (
          <div key={item.number} className="item">
            <div className="texts">
              <div className="title" onClick={() => toggle(i)}>
                <h2 dangerouslySetInnerHTML={{ __html: item.question }} />
                <div>
                  {selected === i ? (
                    <i className="open fas fa-plus"></i>
                  ) : (
                    <i className=" fas fa-plus"></i>
                  )}
                </div>
              </div>
              <div
                className={selected === i ? "content show" : "content"}
                dangerouslySetInnerHTML={{
                  __html: item.answer,
                }}
              />
            </div>
          </div>
        );
      });
    bool === undefined && setLimit((prevValue) => prevValue + 2);
    setElems(a);
  };
  return (
    <div id="faq" className="Faq">
      <div className="Accordion">
        <div className="faq-top">
          <h2>Tez-tez soruşulan suallar</h2>
          <p>
            Bu bölmədə <span>bid</span> istifadəçilərinin mütəmadi olaraq
            göndərdiyi sorğuları görə bilərsiniz.
          </p>
        </div>
        {elems}
        {faq.length !== elems.length ? (
          <button onClick={() => func(faq)}>
            Daha çox yüklə <i className="fas fa-angle-down"></i>
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Faq;
