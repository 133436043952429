import React from "react";
import "../assets/styles/bannerCards.css";
import userImage from "../assets/images/userImage.png";
import driverAppIcon from "../assets/images/userAppIcon.svg";
import googlePlayIcon from "../assets/images/googlePlayIcon.svg";
import AppstoreIcon from "../assets/images/AppstoreIcon.svg";
const UserCard = () => {
  return (
    <div className="driver-card-part">
      <div className="card-banner">
        <div className="right">
          <img src={userImage} alt="driver-image" />
        </div>
        <div
          className="left left-r"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
            padding: "10px",
          }}
        >
          <span
            className="span-card-banner-1"
            style={{
              color: "white !important",
            }}
          >
            Sərnişin
          </span>
          <span
            className="span-card-banner-2"
            style={{
              marginTop: "20px",
              color: "white !important",
            }}
          >
            Taksinizi özünüz seçin
          </span>
          <span
            className="span-card-banner-2"
            style={{
              marginTop: "20px",
              color: "white !important",
            }}
          >
            Hər yerdə sərfəli tarif
          </span>
          <span
            className="span-card-banner-4 small-3-a"
            style={{
              marginTop: "30px",
              fontSize: "45px",
            }}
          >
            3 <span className="span-card-banner-5">AZN</span>
          </span>
          <span className="span-card-banner-3">dan başlayan qiymətlərlə</span>
        </div>
      </div>
      <div className="type-info">
        <img src={driverAppIcon} alt="driver app icon" />
        <span>Bid: Sərnişin</span>
      </div>
      <div className="type-urls">
        <a
          href="https://play.google.com/store/apps/details?id=com.bid.iste"
          target="_blank"
        >
          <img src={googlePlayIcon} alt="google play icon" />
        </a>
        <a
          href="https://apps.apple.com/az/app/bid/id6498927869"
          target="_blank"
        >
          <img src={AppstoreIcon} alt="app store icon" />
        </a>
      </div>
    </div>
  );
};

export default UserCard;
