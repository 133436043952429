import React, { useState, useEffect } from "react";
import "../assets/styles/register.css";
import bidLogoWhite from "../assets/images/bid-logo-wh.svg";
import Slider from "../components/SliderForm";
import RegisterPagePopUp from '../components/RegisterPagePopUp'

function Register() {
  return (
    <div className="register-container">
      <div className="register-left">
        <RegisterPagePopUp/>
      </div>
      <div className="register-right">
        <Slider />
      </div>
    </div>
  );
}

export default Register;
